import {Component, Input, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {HistoriekEntry} from "../core/historiek-entry";
import {LoggerService} from "../core/logger.service";

@Component({
  selector: "app-pboek-footer",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <table *ngIf="historiek$ | async as historiek" width="100%">
      <tr>
        <td class="footer boeken">
          <ng-container *ngIf="rboekLink$ | async as rboekLink">
            <a [routerLink]="rboekLink">referentiewaarden</a>
            <br/>
          </ng-container>
          <a *ngIf="eboekLink$ | async as eboekLink" [routerLink]="eboekLink">aanvraagmenu</a>
        </td>
        <td class="footer historiek-data">
          <ng-container *ngIf="versie === 'intralab'; else notIntralab">
            <ng-container *ngIf="intranetHistoriek$ | async as intranetHistoriek">
              <ng-container *ngIf="intranetHistoriek.length && !intranetHistoriek[0].verwijderd">
                Laatste wijziging <b>intranet</b>:
                <a [routerLink]="intranetLink">{{intranetHistoriek[0].datum}}</a>
                <br/>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="internetHistoriek$ | async as internetHistoriek">
              <ng-container *ngIf="internetHistoriek.length && !internetHistoriek[0].verwijderd">
                Laatste wijziging <b>internet</b>:
                <a [routerLink]="internetLink">{{internetHistoriek[0].datum}}</a>
                <br/>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #notIntralab>
            <div>historiek</div>
            <ng-container *ngFor="let entry of historiek; first as first">
              <ng-container *ngIf="!first">
                <ng-container *ngIf="entry.verwijderd; else notVerwijderd">
                  {{entry.datum}} (verwijderd)
                </ng-container>
                <ng-template #notVerwijderd>
                  <ng-container *ngIf="entry.prehistorie; else notPrehistorie">
                    <a *ngIf="versie !== 'internet'; else isInternet" [href]="getHistoriekLink(entry)" target="_blank">
                      {{entry.datum}}
                    </a>
                    <ng-template #isInternet>{{entry.datum}}</ng-template>
                  </ng-container>
                  <ng-template #notPrehistorie>
                    <a [routerLink]="getHistoriekLink(entry)">{{entry.datum}}</a>
                  </ng-template>
                </ng-template>
                <br/>
              </ng-container>
            </ng-container>
          </ng-template>
        </td>
        <td class="footer datum">
          <ng-container *ngIf="versie !== 'intralab' && historiek.length">{{historiek[0].datum}}</ng-container>
        </td>
      </tr>
    </table>
  `
})
export class PboekFooterComponent implements OnInit {
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie;
  @Input() code: number;
  @Input() intranetHistoriek$: Observable<HistoriekEntry[]>;
  @Input() internetHistoriek$: Observable<HistoriekEntry[]>;
  rboekLink$: Observable<string | null>;
  eboekLink$: Observable<string | null>;

  constructor(private readonly logger: LoggerService,
              private readonly db: DatabaseService) {
  }

  ngOnInit(): void {
    const rboekLinkVersie: BoekVersie = this.versie === 'intralab' ? 'intranet' : this.versie;
    this.rboekLink$ = this.db.getEntiteitLink("rboek", rboekLinkVersie, this.locatie, this.code).pipe(
      catchError(err => {
        this.logger.error(err);
        return of(null);
      })
    );
    this.eboekLink$ = this.db.getEntiteitLink("eboek", "internet", null, this.code).pipe(
      catchError(err => {
        this.logger.error(err);
        return of(null);
      })
    );
  }

  get historiek$(): Observable<HistoriekEntry[]> {
    switch (this.versie) {
      case "intranet":
        return this.intranetHistoriek$;
      case "internet":
        return this.internetHistoriek$;
      case "intralab":
        return of([]);
      default:
        throw new Error("Onbekende versie: " + this.versie);
    }
  }

  get intranetLink(): string {
    return BoekURL.getEntiteitLink("pboek", "intranet", this.locatie, this.code);
  }

  get internetLink(): string {
    return BoekURL.getEntiteitLink("pboek", "internet", this.locatie, this.code);
  }

  getHistoriekLink(entry: HistoriekEntry): string {
    return BoekURL.getHistoriekLink("pboek", this.versie, this.locatie, this.code, entry.datum, entry.prehistorie);
  }
}
