import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  static enabled: boolean = !environment.production;

  debug(message: any, ...params: any[]): void {
    if (LoggerService.enabled) {
      console.debug(message, params);
    }
  }

  info(message: any, ...params: any[]): void {
    if (LoggerService.enabled) {
      console.info(message, params);
    }
  }

  warn(message: any, ...params: any[]): void {
    if (LoggerService.enabled) {
      console.warn(message, params);
    }
  }

  error(message: any, ...params: any[]): void {
    if (LoggerService.enabled) {
      console.error(message, params);
    }
  }
}
