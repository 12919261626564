import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap, RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLabo} from "../shared/boek/boek-labo";
import {BoekLocatie, DatabaseBoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-toc",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      <ng-container *ngIf="entiteiten$ | async as entiteiten">
        <app-export-table [table]="table" filename="inhoudstafel"></app-export-table>
        <table #table class="border">
          <caption>Inhoudstafel</caption>
          <tr>
            <th class="cell">aanvraag</th>
            <th class="cell">testnaam</th>
            <th class="cell" *ngIf="toonLabo()">labo</th>
          </tr>
          <tr *ngFor="let entiteit of entiteiten">
            <td class="cell code-field">{{getAanvraagnummer(entiteit)}}</td>
            <td class="cell">
              <a *ngIf="locatie; else geenLocatie" [routerLink]="['/', type, versie, locatie, entiteit.code]">
                {{entiteit.omschrijving}}
              </a>
              <ng-template #geenLocatie>
                <a [routerLink]="['/', type, versie, entiteit.code]">{{entiteit.omschrijving}}</a>
              </ng-template>
            </td>
            <td class="cell" *ngIf="toonLabo()">{{getLabo(entiteit.locatie)}}</td>
          </tr>
        </table>
      </ng-container>
    </app-pagina-ophalen>
  `,
  styleUrls: ["./overzicht.css"]
})
export class OverzichtTOCComponent implements Overzicht, OnInit {
  entiteiten$: Observable<any[]>;
  type: BoekType;
  versie: BoekVersie;
  locatie: BoekLocatie | null;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entiteiten$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any[]> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const type = BoekTools.getType(params);
        if (!type) {
          throw new Error("Type is vereist");
        }
        this.type = type;
        const versie = BoekTools.getVersie(params);
        if (!versie) {
          throw new Error("Versie is vereist");
        }
        this.versie = versie;
        const locatie = BoekTools.getLocatie(params);
        if (!locatie && this.type !== 'eboek') {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        return this.db.getZoekenOverzicht(this.type, this.versie, this.locatie);
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  getAanvraagnummer(entiteit: any): number | null {
    const aanvraagnummers: any[] | null = entiteit.aanvraagnummer;
    return (aanvraagnummers && aanvraagnummers.length) ? aanvraagnummers[0] : null;
  }

  toonLabo(): boolean {
    return this.locatie === "GHB";
  }

  getLabo(locatie: DatabaseBoekLocatie): BoekLabo {
    return BoekTools.getLabo(locatie);
  }
}
