import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-eboek-staal",
  standalone: true,
  imports: [CommonModule],
  template: `
    <table width="100%">
      <ng-container *ngFor="let staal of stalen">
        <tr *ngIf="staal.specimen as specimen">
          <td class="header">{{specimen}}</td>
        </tr>
        <tr *ngIf="staal.omschrijving as omschrijving">
          <td class="cell">{{omschrijving}}</td>
        </tr>
        <tr>
          <td>
            <table cellpadding="0" cellspacing="0" width="100%">
              <ng-container *ngIf="staal.aanvraagTest as aanvraagTest" width="100%">
                <tr>
                  <td class="special2" colspan="2"><b>Aanvraagbaar en rapporteerbaar via</b></td>
                </tr>
                <tr>
                  <td class="special2">{{aanvraagTest.code}} ({{aanvraagTest.systeem}})</td>
                  <td class="special2"><i>{{aanvraagTest.naam}}</i></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="staal.and?.element as aanvraagTesten">
                <tr>
                  <td class="special2" colspan="3"><b>Aanvraagbaar en rapporteerbaar via</b></td>
                </tr>
                <tr *ngFor="let aanvraagTest of aanvraagTesten; first as first">
                  <td *ngIf="first" class="special1" [attr.rowspan]="aanvraagTesten.length" width="1%">EN</td>
                  <td class="special2">{{aanvraagTest.code}} ({{aanvraagTest.systeem}})</td>
                  <td class="special2"><i>{{aanvraagTest.naam}}</i></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="staal.or?.element as aanvraagTesten">
                <tr>
                  <td class="special2" colspan="3"><b>Aanvraagbaar en rapporteerbaar via</b></td>
                </tr>
                <tr *ngFor="let aanvraagTest of aanvraagTesten; first as first">
                  <td *ngIf="first" class="special1" [attr.rowspan]="aanvraagTesten.length" width="1%">OF</td>
                  <td class="special2">{{aanvraagTest.code}} ({{aanvraagTest.systeem}})</td>
                  <td class="special2"><i>{{aanvraagTest.naam}}</i></td>
                </tr>
              </ng-container>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table *ngIf="staal.recipient as recipienten" width="100%">
              <tr>
                <td colspan="2"><b>Recipiënten</b></td>
              </tr>
              <tr *ngFor="let recipient of recipienten">
                <td>{{recipient.naam}}</td>
                <td *ngIf="recipient.voorkeur"><i>voorkeur</i></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table *ngIf="staal.toegelaten?.aanvraagTest as aanvraagTesten" width="100%">
              <tr>
                <td colspan="2"><b>Rapporteerbaar via</b>
                  <span class="extra-left-margin tooltip">(niet aanvraagbaar)
                    <span class="tooltiptext">Deze codes mogen niet aangevraagd worden, maar dienen wel geaccepteerd te worden.</span>
                  </span>
                </td>
              </tr>
              <tr *ngFor="let aanvraagTest of aanvraagTesten">
                <td>{{aanvraagTest.code}} ({{aanvraagTest.systeem}})</td>
                <td><i>{{aanvraagTest.naam}}</i></td>
              </tr>
            </table>
          </td>
        </tr>
      </ng-container>
    </table>
  `
})
export class EboekStaalComponent {
  @Input() stalen: any[];
}
