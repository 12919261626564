import {Directive, OnInit} from "@angular/core";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {RapportAfnameInstructie} from "./rapport-afname-instructie";
import {RapportRij} from "./rapport-rij";
import {RapportVerantwoordelijke} from "./rapport-verantwoordelijke";

@Directive()
export abstract class OverzichtPboekRapport implements Overzicht, OnInit {
  readonly type: BoekType = "pboek";
  readonly versie: BoekVersie = "intralab";

  tabel$: Observable<RapportRij[]>;
  locatie: BoekLocatie;
  afnameInstructieTitels: string[];

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute,
              private readonly rapportRijMapper: (entiteit: any) => RapportRij) {
  }

  ngOnInit(): void {
    this.tabel$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<RapportRij[]> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const locatie = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map(entiteiten => {
            entiteiten.sort(this.compareEntiteiten);
            this.afnameInstructieTitels = this.getInstructieTitels(entiteiten);
            return entiteiten.map(entiteit => this.rapportRijMapper(entiteit));
          })
        );
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  private compareEntiteiten(e1: any, e2: any): number {
    const omschrijving1: string = e1.omschrijving;
    const omschrijving2: string = e2.omschrijving;
    return omschrijving1.localeCompare(omschrijving2);
  }

  private getInstructieTitels(entiteiten: any[]): string[] {
    const titels = new Set<string>();
    entiteiten.forEach(entiteit =>
      this.getInstructies(entiteit).forEach(instructie => titels.add(instructie.titel))
    );
    return Array.from(titels).sort();
  }

  protected getSynoniemen(entiteit: any): string[] {
    for (const element of entiteit.element) {
      if (element.type === "synoniem") {
        const synoniemen: any[] = element.synoniem;
        return synoniemen.map(synoniem => synoniem.omschrijving);
      }
    }
    return [];
  }

  protected getAfnameMaterialen(entiteit: any): string[] {
    for (const element of entiteit.element) {
      if (element.type === "afnameMateriaal") {
        const afnameMaterialen: string[] = [];
        for (const afnameMateriaal of element.afnameMateriaal) {
          for (const alternatief of afnameMateriaal.afnameMateriaalAlternatief) {
            const tekst = (alternatief.voorkeur && afnameMateriaal.afnameMateriaalAlternatief.length > 1)
              ? alternatief.naam + " *"
              : alternatief.naam;
            afnameMaterialen.push(tekst);
          }
        }
        return afnameMaterialen;
      }
    }
    return [];
  }

  protected getCollectiematerialen(entiteit: any): string[] {
    for (const element of entiteit.element) {
      if (element.type === "collectiemateriaal") {
        const collectiematerialen: string[] = [];
        for (const collectiemateriaal of element.collectiemateriaal) {
          let tekst = "";
          if (collectiemateriaal.aantal > 1) {
            tekst += collectiemateriaal.aantal + "x ";
          }
          if (element.collectiemateriaal.length > 1 && collectiemateriaal.naam) {
            tekst += collectiemateriaal.naam;
          }
          if (tekst) {
            collectiematerialen.push(tekst);
          }
          for (const alternatief of collectiemateriaal.collectiemateriaalAlternatief) {
            collectiematerialen.push(alternatief.naam);
          }
        }
        return collectiematerialen;
      }
    }
    return [];
  }

  protected getVerantwoordelijken(entiteit: any): RapportVerantwoordelijke[] {
    for (const element of entiteit.element) {
      if (element.type === "verantwoordelijke") {
        const verantwoordelijken: any[] = element.verantwoordelijke;
        return verantwoordelijken.map(verantwoordelijke => {
          const naam = verantwoordelijke.voornaam + " " + verantwoordelijke.naam;
          const wieIsWie = BoekURL.getWieIsWie(verantwoordelijke.loginnaam);
          return new RapportVerantwoordelijke(naam, wieIsWie);
        });
      }
    }
    return [];
  }

  protected getAfnameInstructiesMap(entiteit: any, filterTitels?: string[])
    : Map<string, RapportAfnameInstructie[]>
  {
    filterTitels = filterTitels || this.afnameInstructieTitels;
    const map = new Map<string, RapportAfnameInstructie[]>();
    for (const instructieElement of this.getInstructies(entiteit)) {
      const titel: string = instructieElement.titel;
      if (filterTitels.indexOf(titel) === -1) {
        continue;
      }
      const afnameInstructies: RapportAfnameInstructie[] = [];
      for (const instructie of instructieElement.instructie) {
        for (const content of instructie.content) {
          const text: string = content.href || content;
          const isHref = Boolean(content.href);
          afnameInstructies.push(new RapportAfnameInstructie(text, isHref));
        }
      }
      map.set(titel, afnameInstructies);
    }
    return map;
  }

  private getInstructies(entiteit: any): any[] {
    for (const element of entiteit.element) {
      if (element.type === "afnameInstructie") {
        return element.afnameInstructie;
      }
    }
    return [];
  }

  protected getDiagnoseRegels(entiteit: any): string[] {
    for (const element of entiteit.element) {
      if (element.type === "diagnoseRegel") {
        const diagnoseRegels: any[] = element.diagnoseRegel;
        return diagnoseRegels.map(regel => regel.omschrijving);
      }
    }
    return [];
  }

  protected getNietZIVPrijs(entiteit: any): number | null {
    for (const element of entiteit.element) {
      if (element.type === "nietZIVPrijs") {
        return element.nietZIVPrijs;
      }
    }
    return null;
  }

  protected getRIZIVs(entiteit: any): string[] {
    for (const element of entiteit.element) {
      if (element.type === "RIZIV") {
        const rizivs: any[] = element.riziv;
        return rizivs.map(riziv => riziv.nomenclatuurnr);
      }
    }
    return [];
  }

  protected getAanvraagNummers(entiteit: any): number[] {
    const aanvragen: any[] = this.getAanvragen(entiteit);
    return aanvragen.map(aanvraag => aanvraag.nummer);
  }

  protected getFormulierNummers(entiteit: any): any[] {
    const formulierNummers: number[] = [];
    const aanvragen: any[] = this.getAanvragen(entiteit);
    for (const aanvraag of aanvragen) {
      const formulieren: any[] = aanvraag.formulier;
      formulieren.forEach(formulier => formulierNummers.push(formulier.nummer));
    }
    return formulierNummers;
  }

  private getAanvragen(entiteit: any): any[] {
    for (const element of entiteit.element) {
      if (element.type === "aanvraag") {
        return element.aanvraag;
      }
    }
    return [];
  }

  protected getUitvoerendeLabos(entiteit: any): string[] {
    for (const element of entiteit.element) {
      if (element.type === "uitvoerendLabo") {
        const uitvoerendeLabos: any[] = element.uitvoerendLabo;
        return uitvoerendeLabos.map(uitvoerendLabo => uitvoerendLabo.naam);
      }
    }
    return [];
  }

  protected getUitvoerfrequenties(entiteit: any): string[] {
    for (const element of entiteit.element) {
      if (element.type === "uitvoerfrequentie") {
        const uitvoerfrequenties: any[] = element.uitvoerfrequentie;
        return uitvoerfrequenties.map(uitvoerfrequentie => uitvoerfrequentie.uitvoerfrequentie);
      }
    }
    return [];
  }

  protected getUitvoeringstijden(entiteit: any): string[] {
    for (const element of entiteit.element) {
      if (element.type === "uitvoeringstijd") {
        const uitvoeringstijden: any[] = element.uitvoeringstijd;
        return uitvoeringstijden.map(uitvoeringstijd => uitvoeringstijd.uitvoeringstijd);
      }
    }
    return [];
  }
}
