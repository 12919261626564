import {Component} from "@angular/core";

@Component({
  selector: "app-intralab-legende",
  standalone: true,
  template: `
    <div class="legend">
      <span class="intralab legend">Intralab</span><span class="intramuros legend">Intramuros</span><span class="extramuros legend">Extramuros</span>
    </div>
  `
})
export class IntralabLegendeComponent {
}
