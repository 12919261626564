import {Component, Input, inject} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StorageService} from "../core/storage/storage.service";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekURL} from "../shared/boek/boek-url";

@Component({
  selector: "app-pboek-aanvraag",
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngFor="let a of element.aanvraag">
      {{a.nummer}}
      <ng-container *ngFor="let f of a.formulier; first as first; last as last">
        <ng-container *ngIf="first">(</ng-container>
        <ng-container *ngIf="getFormulierDocument(f) | async as document; else geenDocument">
          <a [href]="document" target="_blank">{{f.nummer}}</a>
        </ng-container>
        <ng-template #geenDocument>{{f.nummer}}</ng-template>
        <ng-container *ngIf="!last">, </ng-container>
        <ng-container *ngIf="last">)</ng-container>
      </ng-container>
      <br/>
    </ng-container>
  `
})
export class PboekAanvraagComponent {
  @Input() element: any;
  @Input() locatie: BoekLocatie;
  private readonly storage = inject(StorageService);
  private readonly formulierDocumentCache = new Map<number, Promise<string>>();

  getFormulierDocument(formulier: any): Promise<string> {
    const nummer: number = formulier.nummer;
    let formulierDocument$: Promise<string> | undefined = this.formulierDocumentCache.get(nummer);
    if (!formulierDocument$) {
      formulierDocument$ = BoekURL.getFormulierDocument(this.storage, this.locatie, nummer);
      this.formulierDocumentCache.set(nummer, formulierDocument$);
    }
    return formulierDocument$;
  }
}
