import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Observable} from "rxjs";
import {PaginaService} from "../core/pagina.service";
import {BoekParams} from "../shared/boek/boek-params";
import {ExtraComponent} from "./extra.component";
import {ZoekenComponent} from "./zoeken.component";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [CommonModule, ExtraComponent, ZoekenComponent],
  template: `
    <ng-container *ngIf="params$ | async as params">
      <ng-container *ngIf="params.type && params.versie">
        <app-zoeken [type]="params.type" [versie]="params.versie" [locatie]="params.locatie" [code]="params.code"></app-zoeken>
        <br/>
        <app-extra [type]="params.type" [versie]="params.versie" [locatie]="params.locatie"></app-extra>
      </ng-container>
    </ng-container>
  `
})
export class SidebarComponent implements OnInit {
  params$: Observable<BoekParams>;

  constructor(private readonly pagina: PaginaService) {
  }

  ngOnInit(): void {
    this.params$ = this.pagina.params;
  }
}
