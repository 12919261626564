import {BoekLocatie} from "./boek-locatie";
import {BoekTools} from "./boek-tools";
import {BoekType} from "./boek-type";
import {BoekVersie} from "./boek-versie";
import {LoggerService} from "../../core/logger.service";

export class BoekParams {
  private static readonly LOGGER = new LoggerService();
  static readonly EMPTY = new BoekParams(null, null, null, null);
  private static readonly URL_SEPARATOR = /[/?&;#()]/;

  private constructor(readonly type: BoekType | null,
                      readonly versie: BoekVersie | null,
                      readonly locatie: BoekLocatie | null,
                      readonly code: number | null) {
  }

  static of(type: BoekType | null,
            versie: BoekVersie | null,
            locatie: BoekLocatie | null,
            code: number | null): BoekParams {
    if (!type && !versie && !locatie && !code) {
      return this.EMPTY;
    }
    return new BoekParams(type, versie, locatie, code);
  }

  static from(url: string): BoekParams {
    try {
      const decoded: string = decodeURI(url);
      const split: string[] = decoded.split(this.URL_SEPARATOR);
      let index = 1;
      if (this.isAdmin(split[index])) {
        return BoekParams.EMPTY;
      }
      if (!BoekTools.isType(split[index])) { // URL kan prefix element hebben (zoals "historiek")
        index++;
      }
      const type: BoekType = this.getType(split[index++]);
      const versie: BoekVersie = this.getVersie(split[index++]);
      const locatie: BoekLocatie | null = (type === "eboek") ? null : this.getLocatie(split[index++]);
      const codeString: string | undefined = split[index++];
      const code: number | null = codeString && BoekTools.isCode(codeString) ? this.getCode(codeString) : null;
      return BoekParams.of(type, versie, locatie, code);
    } catch (e) {
      BoekParams.LOGGER.error(e);
      return BoekParams.EMPTY;
    }
  }

  private static isAdmin(s: string): boolean {
    return s === "admin";
  }

  private static getType(s: string): BoekType {
    const type: BoekType | null = BoekTools.getType(s);
    if (!type) {
      throw new Error("Type is vereist");
    }
    return type;
  }

  private static getVersie(s: string): BoekVersie {
    const versie: BoekVersie | null = BoekTools.getVersie(s);
    if (!versie) {
      throw new Error("Versie is vereist");
    }
    return versie;
  }

  private static getLocatie(s: string): BoekLocatie {
    const locatie: BoekLocatie | null = BoekTools.getLocatie(s);
    if (!locatie) {
      throw new Error("Locatie is vereist");
    }
    return locatie;
  }

  private static getCode(s: string): number {
    const code: number | null = BoekTools.getCode(s);
    if (!code) {
      throw new Error("Code is vereist");
    }
    return code;
  }
}
