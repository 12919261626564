import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap, RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTablesComponent} from "../shared/export/export-tables.component";

@Component({
  selector: "app-overzicht-verantwoordelijke",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTablesComponent],
  template: `
    <app-pagina-ophalen>
      <ng-container *ngIf="entiteitGroepen$ | async as entiteitGroepen">
        <nav>
          <ul>
            <li *ngFor="let entiteitGroep of entiteitGroepen">
              <ng-container *ngIf="entiteitGroep[0] as verantwoordelijke">
                <a routerLink="." [fragment]="verantwoordelijke.loginnaam">{{verantwoordelijke.loginnaam}}</a>
                ({{verantwoordelijke.voornaam}} {{verantwoordelijke.naam}})
              </ng-container>
            </li>
          </ul>
        </nav>
        <app-export-tables [tables]="getSubTables(table)" filename="verantwoordelijken"></app-export-tables>
        <table #table class="border">
          <caption>Verantwoordelijke</caption>
          <tr *ngFor="let entiteitGroep of entiteitGroepen">
            <td *ngIf="entiteitGroep[0] as verantwoordelijke" [id]="verantwoordelijke.loginnaam">
              <table class="border">
                <caption class="header fragment-field">
                  &raquo;
                  <a target="_blank" [href]="getWieIsWie(verantwoordelijke)">{{verantwoordelijke.loginnaam}}</a>
                  ({{verantwoordelijke.voornaam}} {{verantwoordelijke.naam}})
                </caption>
                <tr>
                  <th class="header">nummer</th>
                  <th class="cell">testnaam</th>
                  <th class="cell">diagnose</th>
                  <th class="cell">procedure</th>
                  <th class="cell">prijs</th>
                  <th class="cell">form</th>
                </tr>
                <tr *ngFor="let entiteit of entiteitGroep[1]">
                  <td class="header code-field">
                    <a [routerLink]="['/pboek/intralab', locatie, entiteit.code]">{{renderCell(entiteit.code)}}</a>
                  </td>
                  <td class="cell">{{renderCell(entiteit.omschrijving)}}</td>
                  <td class="cell boolean-field">{{renderCell(hasDiagnoseRegels(entiteit))}}</td>
                  <td class="cell boolean-field">{{renderCell(hasAfnameInstructies(entiteit))}}</td>
                  <td class="cell boolean-field">{{renderCell(hasNietZIVPrijs(entiteit))}}</td>
                  <td class="cell boolean-field">{{renderCell(hasFormulier(entiteit))}}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </ng-container>
    </app-pagina-ophalen>
  `,
  styleUrls: ["./overzicht.css"]
})
export class OverzichtVerantwoordelijkeComponent implements Overzicht, OnInit {
  readonly type: BoekType = "pboek";
  readonly versie: BoekVersie = "intralab";

  entiteitGroepen$: Observable<Array<[any, any[]]>>;
  locatie: BoekLocatie;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entiteitGroepen$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<Array<[any, any[]]>> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const locatie = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map(entiteiten => this.getEntiteitGroepen(entiteiten))
        )
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  getVerantwoordelijken(entiteit: any): any[] {
    for (const element of entiteit.element) {
      if (element.type === "verantwoordelijke") {
        return element.verantwoordelijke;
      }
    }
    return [];
  }

  getWieIsWie(verantwoordelijke: any): string {
    return BoekURL.getWieIsWie(verantwoordelijke.loginnaam);
  }

  hasDiagnoseRegels(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "diagnoseRegel");
  }

  hasAfnameInstructies(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "afnameInstructie");
  }

  hasNietZIVPrijs(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "nietZIVPrijs");
  }

  hasFormulier(entiteit: any): boolean {
    for (const element of entiteit.element) {
      if (element.type === "aanvraag") {
        const aanvragen: any[] = element.aanvraag;
        return aanvragen.some(aanvraag => aanvraag.formulier);
      }
    }
    return false;
  }

  renderCell(value: any): string {
    if (typeof(value) === 'boolean') {
      return value ? "*" : "";
    }
    return String(value);
  }

  getSubTables(table: HTMLTableElement): () => [HTMLTableElement, string][] {
    return () => Array.from(table.rows)
      .map((row): [HTMLTableElement, string] => {
        const cell = row.cells[0];
        const table: HTMLTableElement = cell.firstChild as HTMLTableElement;
        const id: string = cell.id;
        return [table, id];
      });
  }

  private getEntiteitGroepen(entiteiten: any[]): Array<[any, any[]]> {
    const verantwoordelijkeMap: Map<string, any> = new Map();
    const entiteitMap: Map<string, any[]> = entiteiten.sort(this.compareEntiteiten)
      .reduce((accumulator: Map<string, any[]>, entiteit: any) => {
        const verantwoordelijken: any[] = this.getVerantwoordelijken(entiteit);
        for (const verantwoordelijke of verantwoordelijken) {
          const loginnaam: string = verantwoordelijke.loginnaam;
          if (accumulator.has(loginnaam)) {
            accumulator.get(loginnaam)!.push(entiteit);
          } else {
            accumulator.set(loginnaam, [entiteit]);
            verantwoordelijkeMap.set(loginnaam, verantwoordelijke);
          }
        }
        return accumulator;
      }, new Map());
    return Array.from(entiteitMap)
      .sort(this.compareVerantwoordelijkeEntries)
      .map((entry: [string, any[]]): [any, any[]] => {
        const loginnaam: string = entry[0];
        const entiteiten: any[] = entry[1];
        const verantwoordelijke: any = verantwoordelijkeMap.get(loginnaam);
        return [verantwoordelijke, entiteiten];
      });
  }

  private compareEntiteiten(e1: any, e2: any): number {
    const omschrijving1: string = e1.omschrijving;
    const omschrijving2: string = e2.omschrijving;
    return omschrijving1.localeCompare(omschrijving2);
  }

  private compareVerantwoordelijkeEntries(v1: [string, any[]], v2: [string, any[]]): number {
    const loginnaam1: string = v1[0];
    const loginnaam2: string = v2[0];
    return loginnaam1.localeCompare(loginnaam2);
  }
}
