import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekVersie} from "../shared/boek/boek-versie";
import {PboekUitvoerfrequentieComponent} from "./pboek-uitvoerfrequentie.component";
import {PboekUitvoeringstijdComponent} from "./pboek-uitvoeringstijd.component";
import {PboekAanvraagComponent} from "./pboek-aanvraag.component";
import {PboekAfnameMateriaalComponent} from "./pboek-afname-materiaal.component";
import {PboekCollectiemateriaalComponent} from "./pboek-collectiemateriaal.component";
import {PboekNietZIVPrijsComponent} from "./pboek-niet-ziv-prijs.component";
import {PboekVerantwoordelijkeComponent} from "./pboek-verantwoordelijke.component";
import {PboekUitvoerendLaboComponent} from "./pboek-uitvoerend-labo.component";
import {PboekDiagnoseRegelComponent} from "./pboek-diagnose-regel.component";
import {PboekAfnameInstructieComponent} from "./pboek-afname-instructie.component";
import {PboekMeesleurComponent} from "./pboek-meesleur.component";
import {PboekRIZIVComponent} from "./pboek-riziv.component";
import {PboekSynoniemComponent} from "./pboek-synoniem.component";
import {PboekCustomComponent} from "./pboek-custom.component";

@Component({
  selector: "app-pboek-data",
  standalone: true,
  imports: [CommonModule, PboekUitvoerfrequentieComponent, PboekUitvoeringstijdComponent, PboekAanvraagComponent,
    PboekAfnameMateriaalComponent, PboekCollectiemateriaalComponent, PboekNietZIVPrijsComponent,
    PboekVerantwoordelijkeComponent, PboekUitvoerendLaboComponent, PboekDiagnoseRegelComponent,
    PboekAfnameInstructieComponent, PboekMeesleurComponent, PboekRIZIVComponent, PboekSynoniemComponent,
    PboekCustomComponent],
  template: `
    <table cellpadding="4" cellspacing="1" class="border" border="0" width="100%">
      <tr>
        <td colspan="2" class="header">{{entiteit.omschrijving}}</td>
      </tr>
      <tr *ngIf="entiteit.plaats">
        <td colspan="2" [class]="'cell ' + entiteit.plaats" style="vertical-align:top;">
          Deze entiteit is enkel <b>{{entiteit.plaats}}</b> aanvraagbaar
        </td>
      </tr>
      <tr *ngFor="let element of entiteit.element">
        <td *ngIf="!element.verbergTitel" class="title" style="text-align:right; vertical-align:top;">
          {{element.titel}}
          <ng-container *ngIf="element.type === 'collectiemateriaal'">
            <br/><a href="https://wiki/x/L4JVDw" target="_blank" class="extra">pediatrische alternatieven</a>
          </ng-container>
        </td>
        <td [attr.colspan]="element.verbergTitel ? 2 : 1" class="cell" style="vertical-align:top;">
          <ng-container [ngSwitch]="element.type">
            <app-pboek-uitvoerfrequentie  *ngSwitchCase="'uitvoerfrequentie'"  [element]="element"></app-pboek-uitvoerfrequentie>
            <app-pboek-uitvoeringstijd    *ngSwitchCase="'uitvoeringstijd'"    [element]="element"></app-pboek-uitvoeringstijd>
            <app-pboek-aanvraag           *ngSwitchCase="'aanvraag'"           [element]="element" [locatie]="locatie"></app-pboek-aanvraag>
            <app-pboek-afname-materiaal   *ngSwitchCase="'afnameMateriaal'"    [element]="element"></app-pboek-afname-materiaal>
            <app-pboek-collectiemateriaal *ngSwitchCase="'collectiemateriaal'" [element]="element" [locatie]="locatie"></app-pboek-collectiemateriaal>
            <app-pboek-niet-ziv-prijs     *ngSwitchCase="'nietZIVPrijs'"       [element]="element"></app-pboek-niet-ziv-prijs>
            <app-pboek-verantwoordelijke  *ngSwitchCase="'verantwoordelijke'"  [element]="element" [versie]="versie" [feedback]="feedback"></app-pboek-verantwoordelijke>
            <app-pboek-uitvoerend-labo    *ngSwitchCase="'uitvoerendLabo'"     [element]="element"></app-pboek-uitvoerend-labo>
            <app-pboek-diagnose-regel     *ngSwitchCase="'diagnoseRegel'"      [element]="element"></app-pboek-diagnose-regel>
            <app-pboek-afname-instructie  *ngSwitchCase="'afnameInstructie'"   [element]="element"></app-pboek-afname-instructie>
            <app-pboek-meesleur           *ngSwitchCase="'meesleur'"           [element]="element" [versie]="versie" [locatie]="locatie"></app-pboek-meesleur>
            <app-pboek-riziv              *ngSwitchCase="'RIZIV'"              [element]="element"></app-pboek-riziv>
            <app-pboek-synoniem           *ngSwitchCase="'synoniem'"           [element]="element"></app-pboek-synoniem>
            <app-pboek-custom             *ngSwitchCase="'custom'"             [element]="element"></app-pboek-custom>
            <div                          *ngSwitchDefault                     class="error">ERROR: inhoud kan niet worden weergegeven</div>
          </ng-container>
        </td>
      </tr>
    </table>
  `
})
export class PboekDataComponent {
  @Input() entiteit: any;
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie;
  @Input() feedback?: string;
}
