import {Directive, OnInit} from "@angular/core";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";
import {Boek} from "../shared/boek/boek";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {HistoriekEntry} from "../core/historiek-entry";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";

@Directive()
export abstract class Eboek implements Boek, OnInit {
  readonly type: BoekType = "eboek";
  readonly locatie = null;

  entiteit$: Observable<any | null>;
  historiek$?: Observable<HistoriekEntry[]>;
  code: number | null;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute,
              readonly versie: BoekVersie) {
  }

  ngOnInit(): void {
    this.entiteit$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        this.code = BoekTools.getCode(params);
        if (!this.code) {
          return of(null);
        }
        this.historiek$ = this.db.getHistoriekOverzicht(this.type, this.versie, null, this.code);
        return this.db.getEntiteit(this.type, this.versie, null, this.code);
      }),
      tap((entiteit: any | null) =>
        this.pagina.setStatus((this.code && !entiteit) ? PaginaOphalenStatus.FAILED : PaginaOphalenStatus.SUCCEEDED)
      ),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of(null);
      })
    );
  }

  getAanvraagNummer(entiteit: any): number | null {
    const aanvragen: any[] | null = entiteit.aanvraag;
    return (aanvragen && aanvragen.length) ? aanvragen[0].nummer : null;
  }
}
