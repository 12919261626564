import {Component, Input, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {HistoriekEntry} from "../core/historiek-entry";
import {LoggerService} from "../core/logger.service";

@Component({
  selector: "app-rboek-footer",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <table *ngIf="historiek$ | async as historiek" width="100%">
      <tr>
        <td class="footer boeken">
          Aanvraagnummer {{aanvraagNummer}}
          <br/>
          <ng-container *ngIf="showLinks">
            <ng-container *ngIf="pboekLink$ | async as pboekLink">
              <ng-container *ngIf="versie === 'internet'; else intranetPboekLinks">
                <a [routerLink]="pboekLink">labogids</a>
                <br/>
              </ng-container>
              <ng-template #intranetPboekLinks>
                <a [routerLink]="pboekLink">labogids (intramuros)</a>
                <br/>
                <ng-container *ngIf="pboekIntralabLink$ | async as pboekIntralabLink">
                  <a [routerLink]="pboekIntralabLink">labogids (intralab)</a>
                  <br/>
                </ng-container>
              </ng-template>
            </ng-container>
            <a *ngIf="eboekLink$ | async as eboekLink" [routerLink]="eboekLink">aanvraagmenu</a>
          </ng-container>
        </td>
        <td class="footer historiek-data">
          <div>historiek</div>
          <ng-container *ngFor="let entry of historiek; first as first">
            <ng-container *ngIf="!first">
              <ng-container *ngIf="entry.verwijderd; else notVerwijderd">
                {{entry.datum}} (verwijderd)
              </ng-container>
              <ng-template #notVerwijderd>
                <ng-container *ngIf="entry.prehistorie; else notPrehistorie">
                  <a *ngIf="versie !== 'internet'; else isInternet" [href]="getHistoriekLink(entry)" target="_blank">
                    {{entry.datum}}
                  </a>
                  <ng-template #isInternet>{{entry.datum}}</ng-template>
                </ng-container>
                <ng-template #notPrehistorie>
                  <a [routerLink]="getHistoriekLink(entry)">{{entry.datum}}</a>
                </ng-template>
              </ng-template>
              <br/>
            </ng-container>
          </ng-container>
        </td>
        <td class="footer datum">
          <ng-container *ngIf="historiek.length">{{historiek[0].datum}}</ng-container>
          <ng-container *ngIf="feedback">
            <br/>
            <a target="_blank" [href]="feedback">
              feedback
            </a>
          </ng-container>
        </td>
      </tr>
    </table>
  `
})
export class RboekFooterComponent implements OnInit {
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie;
  @Input() code: number;
  @Input() aanvraagNummer: number | null;
  @Input() historiek$: Observable<HistoriekEntry[]>;
  @Input() feedback?: string;
  @Input() showLinks: boolean = true;
  pboekLink$: Observable<string | null>;
  pboekIntralabLink$: Observable<string | null>;
  eboekLink$: Observable<string | null>;

  constructor(private readonly logger: LoggerService,
              private readonly db: DatabaseService) {
  }

  ngOnInit(): void {
    this.pboekLink$ = this.db.getEntiteitLink("pboek", this.versie, this.locatie, this.code).pipe(
      catchError(err => {
        this.logger.error(err);
        return of(null);
      })
    );
    this.pboekIntralabLink$ = this.db.getEntiteitLink("pboek", "intralab", this.locatie, this.code).pipe(
      catchError(err => {
        this.logger.error(err);
        return of(null);
      })
    );
    this.eboekLink$ = this.db.getEntiteitLink("eboek", "internet", null, this.code).pipe(
      catchError(err => {
        this.logger.error(err);
        return of(null);
      })
    );
  }

  getHistoriekLink(entry: HistoriekEntry): string {
    return BoekURL.getHistoriekLink("rboek", this.versie, this.locatie, this.code, entry.datum, entry.prehistorie);
  }
}
