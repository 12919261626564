import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Router, RouterModule} from "@angular/router";
import {Observable} from "rxjs";
import {PaginaService} from "../core/pagina.service";
import {BoekParams} from "../shared/boek/boek-params";
import {BoekType} from "../shared/boek/boek-type";

@Component({
  selector: "app-sidebar-rboek-rapport",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <ng-container *ngIf="params$ | async as params">
      <ng-container *ngIf="params.versie && params.locatie">
        Geef een lijst van
        <a [routerLink]="['/aanvraag', type, params.versie, params.locatie]">aanvraagnummers</a>
        (comma-separated):<br/>
        <textarea #aanvragen rows="10" cols="40" autofocus></textarea><br/>
        <button (click)="genereerLijst(aanvragen.value)">Genereren</button>
        <button (click)="printLijst()">Afdrukken</button>
        <button (click)="gaTerug()">Terug naar zoeken</button>
      </ng-container>
    </ng-container>
  `
})
export class SidebarRboekRapportComponent implements OnInit {
  readonly type: BoekType = "rboek";
  params$: Observable<BoekParams>;

  constructor(private readonly router: Router,
              private readonly pagina: PaginaService) {
  }

  ngOnInit(): void {
    this.params$ = this.pagina.params;
  }

  genereerLijst(nummers: string): void {
    const params: BoekParams = this.pagina.snapshot.params;
    this.router.navigate(['/rapport', this.type, params.versie, params.locatie, {nummers: nummers.trim()}]);
  }

  printLijst(): void {
    print();
  }

  gaTerug(): void {
    this.router.navigate([{outlets: {sidebar: null}}]);
  }
}
