import {CanonicalTranslator} from "./canonical-translator";
import {FuzzyTranslator} from "./fuzzy-translator";
import {Translator} from "./translator";

export class Matcher {
  private static readonly CANONICAL = new CanonicalTranslator();
  private static readonly FUZZY = new FuzzyTranslator();

  private readonly nummerTerm: number | null;
  private readonly canonicalTermen: string[] | null;
  private readonly fuzzyTermen: string[] | null;

  constructor(zoekterm: string, fuzzy: boolean) {
    const term: string = zoekterm.trim();
    if (/^-?\d+$/.test(term)) {
      this.nummerTerm = +term;
      this.canonicalTermen = null;
      this.fuzzyTermen = null;
    } else {
      this.nummerTerm = null;
      this.canonicalTermen = Matcher.CANONICAL.translate(term, false).toUpperCase().split(" ");
      this.fuzzyTermen = fuzzy ? Matcher.FUZZY.translate(term, false).toUpperCase().split(" ") : null;
    }
  }

  matches(entiteit: any): boolean {
    if (this.nummerTerm) {
      return this.nummerMatches(entiteit);
    }
    if (this.canonicalTermen) {
      let match: boolean = this.tekstMatches(Matcher.CANONICAL, this.canonicalTermen, entiteit);
      if (!match && this.fuzzyTermen) {
        match = this.tekstMatches(Matcher.FUZZY, this.fuzzyTermen, entiteit)
      }
      return match;
    }
    throw new Error("Geen zoekterm");
  }

  private nummerMatches(entiteit: any): boolean {
    const aanvraagnummers: number[] = entiteit.aanvraagnummer;
    if (!aanvraagnummers || !aanvraagnummers.length) {
      return false;
    }
    return aanvraagnummers.some((aanvraagnummer: number) => aanvraagnummer === this.nummerTerm);
  }

  private tekstMatches(translator: Translator, zoektermen: string[], entiteit: any): boolean {
    if (this.tekstMatchesCommon(translator, zoektermen, entiteit.omschrijving)) {
      return true;
    }
    const synoniemen: string[] = entiteit.synoniem;
    if (synoniemen && synoniemen.length
      && synoniemen.some((synoniem: string) => this.tekstMatchesCommon(translator, zoektermen, synoniem))) {
      return true;
    }
    const indextermen: string[] = entiteit.indexterm;
    if (indextermen && indextermen.length
      && indextermen.some((indexterm: string) => this.tekstMatchesCommon(translator, zoektermen, indexterm))
    ) {
      return true;
    }
    return false;
  }

  private tekstMatchesCommon(translator: Translator, zoektermen: string[], inhoud: string): boolean {
    const tlInhoud: string = translator.translate(inhoud.trim(), true).toUpperCase();
    return zoektermen.every((zoekterm: string) => tlInhoud.includes(zoekterm));
  }
}
