import {Component, Input} from "@angular/core";

@Component({
  selector: "app-pboek-custom",
  standalone: true,
  template: `
    <div [class]="'custom ' + element.plaats" [innerHTML]="element.inhoud"></div>
  `
})
export class PboekCustomComponent {
  @Input() element: any;
}
