import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {BoekParams} from "../shared/boek/boek-params";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";

@Injectable({
  providedIn: "root"
})
export class PaginaService {
  private _snapshot = PaginaServiceSnapshot.EMPTY;
  private readonly _params = new BehaviorSubject<BoekParams>(BoekParams.EMPTY);
  private readonly _status = new BehaviorSubject<PaginaOphalenStatus>(PaginaOphalenStatus.TODO);

  get snapshot(): PaginaServiceSnapshot {
    return this._snapshot;
  }

  get params(): Observable<BoekParams> {
    return this._params.asObservable();
  }

  setParams(arg: BoekParams | string): void {
    const params: BoekParams = (typeof arg === "string") ? BoekParams.from(arg) : arg;
    this._params.next(params);
    this._snapshot = this._snapshot.withParams(params);
  }

  resetParams(): void {
    this.setParams(BoekParams.EMPTY);
  }

  get status(): Observable<PaginaOphalenStatus> {
    return this._status.asObservable();
  }

  setStatus(status: PaginaOphalenStatus): void {
    this._status.next(status);
    this._snapshot = this._snapshot.withStatus(status);
  }

  resetStatus(): void {
    this.setStatus(PaginaOphalenStatus.TODO);
  }
}

export class PaginaServiceSnapshot {
  static readonly EMPTY = new PaginaServiceSnapshot(BoekParams.EMPTY, PaginaOphalenStatus.TODO);

  private constructor(readonly params: BoekParams, readonly status: PaginaOphalenStatus) {
  }

  withParams(params: BoekParams): PaginaServiceSnapshot {
    if (params === this.params) {
      return this;
    }
    return new PaginaServiceSnapshot(params, this.status);
  }

  withStatus(status: PaginaOphalenStatus): PaginaServiceSnapshot {
    if (status === this.status) {
      return this;
    }
    return new PaginaServiceSnapshot(this.params, status);
  }
}
