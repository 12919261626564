import {BoekLocatie} from "./boek-locatie";
import {BoekType} from "./boek-type";
import {BoekVersie} from "./boek-versie";
import {StorageService} from "../../core/storage/storage.service";
import {StorageDirectories, StorageDirectory} from "../../core/storage/storage-directory";

export class BoekURL {
  private static readonly STORAGE_PREFIX = "gs://uz-laboboeken.appspot.com/"

  private constructor() {
  }

  static getFormulierDocument(storage: StorageService, locatie: BoekLocatie, nummer: number): Promise<string> {
    if (nummer === 111500) {
      return Promise.resolve("https://www.rodekruis.be/storage/dvb/voorschriftformulier-hila.pdf");
    }
    const documenten: Promise<string>[] = BoekURL.getDirectories(locatie)
        .map((dir: string) => storage.getFileURL(`${BoekURL.STORAGE_PREFIX}${dir}/FOR_${nummer}.pdf`));
    return Promise.any(documenten);
  }

  static getCollectiemateriaalFoto(storage: StorageService, locatie: BoekLocatie, afkorting: string): Promise<string> {
    const fotos: Promise<string>[] = BoekURL.getDirectories(locatie)
        .map((dir: string) => storage.getFileURL(`${BoekURL.STORAGE_PREFIX}${dir}/${afkorting}.jpg`));
    return Promise.any(fotos);
  }

  private static getDirectories(locatie: BoekLocatie): string[] {
    let dirs: StorageDirectory[];
    switch (locatie) {
      case "GHB":
        dirs = ["GHB LAG", "GHB CME", "GHB PO"];
        break;
      case "DIEST":
        dirs = ["DIEST", "GHB LAG"]; // GHB LAG is fallback voor DIEST
        break;
    }
    return dirs.map(dir => StorageDirectories.toPath(dir));
  }

  static getWieIsWie(loginnaam: string): string {
    return `https://intranet.uz.kuleuven.ac.be/wie/${loginnaam}`;
  }

  static getFeedback(type: BoekType, versie: BoekVersie, omschrijving: string, nummer: number | null, mail: string)
    : string
  {
    let boek: string;
    switch (type) {
      case "pboek":
        boek = "PBoek";
        break;
      case "rboek":
        boek = "RBoek";
        break;
      case "eboek":
        boek = "EBoek";
        break;
      default:
        throw new Error("Onbekend type: " + type);
    }
    switch (versie) {
      case "intranet":
      case "intralab":
        return `https://intranet.uz.kuleuven.ac.be/formgen/index.jsp?__form=laboboeken&procedure=${boek} ${omschrijving} (${nummer})&verantwoordelijke=${mail}`;
      case "internet":
        return `https://www.uzleuven.be/nl/laboratoriumgeneeskunde/boekenfeedback?wf1=${boek} feedback ${omschrijving} (${nummer})&wf2=${mail}`;
      default:
        throw new Error("Onbekende versie: " + versie);
    }
  }

  static getEntiteitLink(type: BoekType, versie: BoekVersie, locatie: BoekLocatie | null, code: number) {
    return locatie ? `/${type}/${versie}/${locatie}/${code}` : `/${type}/${versie}/${code}`;
  }

  static getHistoriekLink(type: BoekType,
                          versie: BoekVersie,
                          locatie: BoekLocatie | null,
                          code: number,
                          datum: string,
                          prehistorie: boolean)
    : string
  {
    if (prehistorie) {
      return this.getPrehistorieLink(type, versie, code, datum);
    } else if (locatie) {
      return `/historiek/${type}/${versie}/${locatie}/${code}/${datum}`;
    } else {
      return `/historiek/${type}/${versie}/${code}/${datum}`;
    }
  }

  private static getPrehistorieLink(type: BoekType, versie: BoekVersie, code: number, datum: string): string {
    let url: string | null = null;
    switch (type) {
      case "pboek":
        switch (versie) {
          case "intranet":
            url = "https://intranet.uz.kuleuven.ac.be/laboboeken/pboek/intranet";
            break;
          //TODO verhuizen naar een extern bereikbare URL
          // case "internet":
          //   url = "https://intranet.uz.kuleuven.ac.be/laboboeken/pboek/internet";
          //   break;
          case "intralab":
            url = "https://intranet.uz.kuleuven.ac.be/laboboeken/pboek/intralab";
            break;
        }
        break;
      case "rboek":
        switch (versie) {
          case "intranet":
            url = "https://intranet.uz.kuleuven.ac.be/laboboeken/rboek/intranet";
            break;
          //TODO verhuizen naar een extern bereikbare URL
          // case "internet":
          //   url = "https://intranet.uz.kuleuven.ac.be/laboboeken/rboek/internet";
          //   break;
        }
        break;
      case "eboek":
        switch (versie) {
          //TODO verhuizen naar een extern bereikbare URL
          // case "internet":
          //   url = "https://intranet.uz.kuleuven.ac.be/laboboeken/eboek/internet";
          //   break;
        }
        break;
    }
    if (!url) {
      throw new Error(`Geen URL gevonden: type=${type}, versie=${versie}, code=${code}, datum=${datum}`);
    }
    return `${url}/history/${code}/${datum}.html`;
  }
}
