import {Component} from "@angular/core";

@Component({
  selector: "app-page-not-found",
  standalone: true,
  template: `
    <h2>Pagina niet gevonden</h2>
  `
})
export class PageNotFoundComponent {
}
