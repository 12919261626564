import {Directive, OnInit} from "@angular/core";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";
import {Boek} from "../shared/boek/boek";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {HistoriekEntry} from "../core/historiek-entry";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";

@Directive()
export abstract class Pboek implements Boek, OnInit {
  readonly type: BoekType = "pboek";

  entiteit$: Observable<any | null>;
  locatie: BoekLocatie;
  code: number | null;

  intranetHistoriek$?: Observable<HistoriekEntry[]>;
  internetHistoriek$?: Observable<HistoriekEntry[]>;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute,
              readonly versie: BoekVersie) {
  }

  ngOnInit(): void {
    this.entiteit$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const locatie: BoekLocatie | null = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        this.code = BoekTools.getCode(params);
        if (!this.code) {
          return of(null);
        }
        this.intranetHistoriek$ = this.db.getHistoriekOverzicht(this.type, "intranet", this.locatie, this.code);
        this.internetHistoriek$ = this.db.getHistoriekOverzicht(this.type, "internet", this.locatie, this.code);
        return this.db.getEntiteit(this.type, this.versie, this.locatie, this.code);
      }),
      tap((entiteit: any | null) =>
        this.pagina.setStatus((this.code && !entiteit) ? PaginaOphalenStatus.FAILED : PaginaOphalenStatus.SUCCEEDED)
      ),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of(null);
      })
    );
  }

  getAanvraagNummer(entiteit: any): number | null {
    for (const element of entiteit.element) {
      if (element.type === "aanvraag") {
        return element.aanvraag[0].nummer;
      }
    }
    return null;
  }

  protected getVerantwoordelijkeMail(entiteit: any): string | null {
    for (const element of entiteit.element) {
      if (element.type === "verantwoordelijke") {
        return element.verantwoordelijke[0].email;
      }
    }
    return null;
  }

  getFeedback(entiteit: any): string {
    const omschrijving: string = entiteit.omschrijving;
    const nummer: number | null = this.getAanvraagNummer(entiteit);
    const mail: string = this.getVerantwoordelijkeMail(entiteit) || "";
    return BoekURL.getFeedback(this.type, this.versie, omschrijving, nummer, mail);
  }
}
