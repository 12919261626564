import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap, RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-testnaam",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      <ng-container *ngIf="entiteiten$ | async as entiteiten">
        <app-export-table [table]="table" filename="testnamen"></app-export-table>
        <table #table class="border">
          <caption>Testnaam</caption>
          <tr>
            <th class="header">nummer</th>
            <th class="cell">testnaam</th>
            <th class="cell">diagnose</th>
            <th class="cell">procedure</th>
            <th class="cell">prijs</th>
            <th class="cell">super</th>
            <th class="cell">form</th>
          </tr>
          <tr *ngFor="let entiteit of entiteiten">
            <td class="header code-field">
              <a [routerLink]="['/pboek/intralab', locatie, entiteit.code]">{{renderCell(entiteit.code)}}</a>
            </td>
            <td class="cell">{{renderCell(entiteit.omschrijving)}}</td>
            <td class="cell boolean-field">{{renderCell(hasDiagnoseRegels(entiteit))}}</td>
            <td class="cell boolean-field">{{renderCell(hasAfnameInstructies(entiteit))}}</td>
            <td class="cell boolean-field">{{renderCell(hasNietZIVPrijs(entiteit))}}</td>
            <td class="cell boolean-field">{{renderCell(hasVerantwoordelijke(entiteit))}}</td>
            <td class="cell boolean-field">{{renderCell(hasFormulier(entiteit))}}</td>
          </tr>
        </table>
      </ng-container>
    </app-pagina-ophalen>
  `,
  styleUrls: ["./overzicht.css"]
})
export class OverzichtTestnaamComponent implements Overzicht, OnInit {
  readonly type: BoekType = "pboek";
  readonly versie: BoekVersie = "intralab";

  entiteiten$: Observable<any[]>;
  locatie: BoekLocatie;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entiteiten$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any[]> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const locatie = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map(entiteiten => entiteiten.sort(this.compareEntiteiten))
        )
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  hasDiagnoseRegels(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "diagnoseRegel");
  }

  hasAfnameInstructies(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "afnameInstructie");
  }

  hasNietZIVPrijs(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "nietZIVPrijs");
  }

  hasVerantwoordelijke(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "verantwoordelijke");
  }

  hasFormulier(entiteit: any): boolean {
    for (const element of entiteit.element) {
      if (element.type === "aanvraag") {
        const aanvragen: any[] = element.aanvraag;
        return aanvragen.some(aanvraag => aanvraag.formulier);
      }
    }
    return false;
  }

  renderCell(value: any): string {
    if (typeof(value) === 'boolean') {
      return value ? "*" : "";
    }
    return String(value);
  }

  private compareEntiteiten(e1: any, e2: any): number {
    const omschrijving1: string = e1.omschrijving;
    const omschrijving2: string = e2.omschrijving;
    return omschrijving1.localeCompare(omschrijving2);
  }
}
