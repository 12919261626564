import {RapportVerantwoordelijke} from "./rapport-verantwoordelijke";
import {RapportAfnameInstructie} from "./rapport-afname-instructie";

export class RapportRij {
  constructor(readonly entiteitCode: number,
              readonly entiteitOmschrijving: string,
              readonly synoniemen: string[],
              readonly afnameMaterialen: string[],
              readonly collectiematerialen: string[],
              readonly verantwoordelijken: RapportVerantwoordelijke[],
              readonly afnameInstructiesMap: Map<string, RapportAfnameInstructie[]>,
              readonly diagnoseRegels: string[],
              readonly nietZIVPrijs: number | null,
              readonly rizivs: string[],
              readonly aanvraagNummers: number[],
              readonly formulierNummers: number[],
              readonly uitvoerendeLabos: string[],
              readonly uitvoerfrequenties: string[],
              readonly uitvoeringstijden: string[]) {
  }
}
