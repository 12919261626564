import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {RboekHistoriek} from "./rboek-historiek";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {RboekDataComponent} from "./rboek-data.component";
import {RboekHistoriekFooterComponent} from "./rboek-historiek-footer.component";

@Component({
  selector: "app-rboek-internet-historiek",
  standalone: true,
  imports: [CommonModule, PaginaOphalenComponent, RboekDataComponent, RboekHistoriekFooterComponent],
  templateUrl: "./rboek-historiek.component.html"
})
export class RboekInternetHistoriekComponent extends RboekHistoriek {
  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, "internet");
  }
}
