import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-afname-instructie",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngFor="let ai of element.afnameInstructie; last as last" [class]="ai.plaats">
      <b>{{ai.titel}}</b>
      <br/>
      <ng-container *ngFor="let i of ai.instructie">
        <ng-container *ngFor="let c of i.content">
          <a *ngIf="c.href; else notHref" [href]="c.href" target="_blank">{{c.href}}</a>
          <ng-template #notHref><span class="preserve-text">{{c}}</span></ng-template>
        </ng-container>
        <br/>
      </ng-container>
      <hr *ngIf="!last"/>
    </div>
  `
})
export class PboekAfnameInstructieComponent {
  @Input() element: any;
}
