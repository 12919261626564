import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-afname-materiaal",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngFor="let m of element.afnameMateriaal; last as last" [class]="m.plaats">
      <ng-container *ngIf="m.naam">
        <b *ngIf="m.naam">{{m.naam}}</b>
        <br/>
      </ng-container>
      <ng-container *ngFor="let a of m.afnameMateriaalAlternatief; last as last">
        {{a.naam}}<ng-container *ngIf="a.voorkeur && m.afnameMateriaalAlternatief.length > 1"> (voorkeur)</ng-container>
        <br *ngIf="!last"/>
      </ng-container>
      <hr *ngIf="!last"/>
    </div>
  `
})
export class PboekAfnameMateriaalComponent {
  @Input() element: any;
}
