import {Route, UrlMatchResult, UrlSegment, UrlSegmentGroup} from "@angular/router";

/**
 * Deze matcher laat een path toe als die nog hoogstens 1 segment heeft.
 * Dit segment wordt bijgehouden als de "code" parameter.
 */
export function codeParameterMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
  return segments.length === 1
    ? {consumed: segments, posParams: {code: segments[0]}}
    : {consumed: []};
}
