import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-uitvoerfrequentie",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngFor="let u of element.uitvoerfrequentie" [class]="u.plaats">
      {{u.uitvoerfrequentie}}<br/>
    </div>
  `
})
export class PboekUitvoerfrequentieComponent {
  @Input() element: any;
}
