import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {BoekVersie} from "../shared/boek/boek-versie";
import {RboekTestComponent} from "./rboek-test.component";

@Component({
  selector: "app-rboek-data",
  standalone: true,
  imports: [CommonModule, RouterModule, RboekTestComponent],
  template: `
    <table cellpadding="4" cellspacing="1" class="border" border="0" width="100%">
      <tr>
        <td class="header">{{entiteit.omschrijving}}</td>
      </tr>
      <tr *ngIf="entiteit.synoniem?.length">
        <td class="cell" style="vertical-align:top;">
          <ng-container *ngFor="let synoniem of entiteit.synoniem; last as last">
            {{synoniem.omschrijving}}<ng-container *ngIf="!last">; </ng-container>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="!entiteit.test?.length">
        <td class="cell">Geen referentiewaarden!</td>
      </tr>
      <ng-container *ngIf="entiteit.test?.length > 1">
        <tr>
          <td class="cell">
            <ul>
              <li *ngFor="let test of entiteit.test">
                <a routerLink="." [fragment]="'t' + test.code">{{test.omschrijving}}</a>
              </li>
            </ul>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngFor="let test of entiteit.test">
        <tr>
          <td class="subheader" style="vertical-align:top;"><a [id]="'t' + test.code">{{test.omschrijving}}</a></td>
        </tr>
        <tr>
          <td class="cell">
            <app-rboek-test [test]="test" [versie]="versie"></app-rboek-test>
          </td>
        </tr>
      </ng-container>
    </table>
  `
})
export class RboekDataComponent {
  @Input() entiteit: any;
  @Input() versie: BoekVersie;
}
