import {Injectable} from "@angular/core";
import {Storage, ref, getMetadata, getDownloadURL, listAll, uploadBytesResumable, deleteObject} from "@angular/fire/storage";
import {StorageReference, FullMetadata, ListResult, UploadTask} from "firebase/storage";
import {LoggerService} from "../logger.service";
import {StorageFile} from "./storage-file";

@Injectable({
  providedIn: "root"
})
export class StorageService {
  constructor(private readonly logger: LoggerService,
              private readonly storage: Storage) {
  }

  getFile(path: string): Promise<StorageFile> {
    const storageRef: StorageReference = ref(this.storage, path);
    const promise: Promise<StorageFile> = Promise.all([getMetadata(storageRef), getDownloadURL(storageRef)])
        .then(([metadata, downloadURL]: [FullMetadata, string]) => {
          const customMetadata = metadata.customMetadata;
          return {
            path: metadata.fullPath,
            name: metadata.name,
            size: metadata.size,
            created: metadata.timeCreated,
            owner: customMetadata ? customMetadata["owner"] : "",
            group: customMetadata ? customMetadata["group"] : "",
            url: downloadURL
          };
        });
    promise
        .then(() => this.logger.info("storage: getFile", path))
        .catch(err => this.logger.error("storage: getFile failed:", err.message));
    return promise;
  }

  getFiles(dir: string): Promise<StorageFile[]> {
    const promise: Promise<StorageFile[]> = listAll(ref(this.storage, dir))
        .then((list: ListResult) => list.items.map((item: StorageReference) => this.getFile(item.fullPath)))
        .then((files: Promise<StorageFile>[]) => Promise.all(files));
    promise
        .then(() => this.logger.info("storage: getFiles", dir))
        .catch(err => this.logger.error("storage: getFiles failed:", err.message));
    return promise;
  }

  getFileURL(path: string): Promise<string> {
    const url: Promise<string> = getDownloadURL(ref(this.storage, path));
    url
        .then(() => this.logger.info("storage: getFileURL", path))
        .catch(err => this.logger.error("storage: getFileURL failed:", err.message));
    return url;
  }

  uploadFile(path: string, file: File, owner: string, group: string): UploadTask {
    const customMetadata = {customMetadata: {owner: owner, group: group}};
    const task: UploadTask = uploadBytesResumable(ref(this.storage, path), file, customMetadata);
    task
        .then(() => this.logger.info("storage: uploadFile", path))
        .catch(err => this.logger.error("storage: uploadFile failed:", err.message));
    return task;
  }

  deleteFile(path: string): Promise<void> {
    const promise: Promise<void> = deleteObject(ref(this.storage, path));
    promise
        .then(() => this.logger.info("storage: deleteFile", path))
        .catch(err => this.logger.error("storage: deleteFile failed:", err.message));
    return promise;
  }
}
