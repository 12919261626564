import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";

@Component({
  selector: "app-pboek-verantwoordelijke",
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngFor="let v of element.verantwoordelijke; first as first">
      <ng-container *ngIf="versie === 'internet'; else link">{{v.voornaam}} {{v.naam}}</ng-container>
      <ng-template #link><a [href]="getWieIsWie(v)" target="_blank">{{v.voornaam}} {{v.naam}}</a></ng-template>
      <ng-container *ngIf="feedback && first"> (<a target="_blank" [href]="feedback">feedback</a>)</ng-container>
      <br/>
    </ng-container>
  `
})
export class PboekVerantwoordelijkeComponent {
  @Input() element: any;
  @Input() versie: BoekVersie;
  @Input() feedback?: string;

  getWieIsWie(verantwoordelijke: any): string {
    return BoekURL.getWieIsWie(verantwoordelijke.loginnaam);
  }
}
