import {Routes} from "@angular/router";
import {BijlageComponent} from "./admin/bijlage.component";
import {StorageComponent} from "./admin/storage.component";
import {RedirectComponent} from "./core/redirect.component";
import {EboekInternetComponent} from "./eboek/eboek-internet.component";
import {EboekInternetHistoriekComponent} from "./eboek/eboek-internet-historiek.component";
import {OverzichtTOCComponent} from "./overzicht/overzicht-toc.component";
import {OverzichtNietZIVComponent} from "./overzicht/overzicht-niet-ziv.component";
import {OverzichtDiagnoseRegelComponent} from "./overzicht/overzicht-diagnose-regel.component";
import {OverzichtCollectiemateriaalComponent} from "./overzicht/overzicht-collectiemateriaal.component";
import {OverzichtTestnaamComponent} from "./overzicht/overzicht-testnaam.component";
import {OverzichtVerantwoordelijkeComponent} from "./overzicht/overzicht-verantwoordelijke.component";
import {OverzichtFormulierComponent} from "./overzicht/overzicht-formulier.component";
import {OverzichtGlobaalComponent} from "./overzicht/overzicht-globaal.component";
import {OverzichtProcedureComponent} from "./overzicht/overzicht-procedure.component";
import {OverzichtFrequentieComponent} from "./overzicht/overzicht-frequentie.component";
import {OverzichtScopeComponent} from "./overzicht/overzicht-scope.component";
import {OverzichtRecipientComponent} from "./overzicht/overzicht-recipient.component";
import {OverzichtRboekRapportComponent} from "./overzicht/overzicht-rboek-rapport.component";
import {OverzichtEboekRapportComponent} from "./overzicht/overzicht-eboek-rapport.component";
import {OverzichtAanvraagComponent} from "./overzicht/overzicht-aanvraag.component";
import {PboekIntranetComponent} from "./pboek/pboek-intranet.component";
import {PboekInternetComponent} from "./pboek/pboek-internet.component";
import {PboekIntralabComponent} from "./pboek/pboek-intralab.component";
import {PboekIntranetHistoriekComponent} from "./pboek/pboek-intranet-historiek.component";
import {PboekInternetHistoriekComponent} from "./pboek/pboek-internet-historiek.component";
import {codeParameterMatcher} from "./shared/router/url-matcher-tools";
import {RboekIntranetComponent} from "./rboek/rboek-intranet.component";
import {RboekInternetComponent} from "./rboek/rboek-internet.component";
import {RboekIntranetHistoriekComponent} from "./rboek/rboek-intranet-historiek.component";
import {RboekInternetHistoriekComponent} from "./rboek/rboek-internet-historiek.component";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {SidebarRboekRapportComponent} from "./sidebar/sidebar-rboek-rapport.component";

const pboekRoutes: Routes = [
  {
    path: "pboek",
    children: [{
      path: ":versie/GHB CME",
      redirectTo: ":versie/GHB"
    }, {
      path: "intranet/:locatie",
      children: [{
        matcher: codeParameterMatcher,
        component: PboekIntranetComponent
      }]
    }, {
      path: "internet/:locatie",
      children: [{
        matcher: codeParameterMatcher,
        component: PboekInternetComponent
      }]
    }, {
      path: "intralab/:locatie",
      children: [{
        matcher: codeParameterMatcher,
        component: PboekIntralabComponent
      }]
    }]
  }, {
    path: "historiek/pboek",
    children: [{
      path: ":versie/GHB CME",
      redirectTo: ":versie/GHB"
    }, {
      path: "intranet/:locatie/:code/:datum",
      component: PboekIntranetHistoriekComponent
    }, {
      path: "internet/:locatie/:code/:datum",
      component: PboekInternetHistoriekComponent
    }]
  }
];

const rboekRoutes: Routes = [
  {
    path: "rboek",
    children: [{
      path: ":versie/GHB CME",
      redirectTo: ":versie/GHB"
    }, {
      path: "intranet/:locatie",
      children: [{
        matcher: codeParameterMatcher,
        component: RboekIntranetComponent
      }]
    }, {
      path: "internet/:locatie",
      children: [{
        matcher: codeParameterMatcher,
        component: RboekInternetComponent
      }]
    }]
  }, {
    path: "historiek/rboek",
    children: [{
      path: ":versie/GHB CME",
      redirectTo: ":versie/GHB"
    }, {
      path: "intranet/:locatie/:code/:datum",
      component: RboekIntranetHistoriekComponent
    }, {
      path: "internet/:locatie/:code/:datum",
      component: RboekInternetHistoriekComponent
    }]
  }
];

const eboekRoutes: Routes = [
  {
    path: "eboek",
    children: [{
      path: "internet",
      children: [{
        matcher: codeParameterMatcher,
        component: EboekInternetComponent
      }]
    }]
  }, {
    path: "historiek/eboek",
    children: [{
      path: "internet/:code/:datum",
      component: EboekInternetHistoriekComponent
    }]
  }
];

const sidebarRoutes: Routes = [
  {path: "", component: SidebarComponent, outlet: "sidebar"},
  {path: "rboek-rapport", component: SidebarRboekRapportComponent, outlet: "sidebar"}
];

const overzichtRoutes: Routes = [
  {
    path: ":query/:type/:versie/GHB CME",
    redirectTo: ":query/:type/:versie/GHB"
  }, {
    path: "toc/:type/:versie",
    children: [{
      path: "",
      component: OverzichtTOCComponent
    }, {
      path: ":locatie",
      component: OverzichtTOCComponent
    }]
  }, {
    path: "nietziv/pboek/:versie/:locatie",
    component: OverzichtNietZIVComponent
  }, {
    path: "diagnoseregel/pboek/:versie/:locatie",
    component: OverzichtDiagnoseRegelComponent
  }, {
    path: "collectiemateriaal/pboek/:versie/:locatie",
    component: OverzichtCollectiemateriaalComponent
  }, {
    path: "testnaam/pboek/intralab/:locatie",
    component: OverzichtTestnaamComponent
  }, {
    path: "verantwoordelijke/pboek/intralab/:locatie",
    component: OverzichtVerantwoordelijkeComponent
  }, {
    path: "formulier/pboek/intralab/:locatie",
    component: OverzichtFormulierComponent
  }, {
    path: "globaal/pboek/intralab/:locatie",
    component: OverzichtGlobaalComponent
  }, {
    path: "procedure/pboek/intralab/:locatie",
    component: OverzichtProcedureComponent
  }, {
    path: "frequentie/pboek/intralab/:locatie",
    component: OverzichtFrequentieComponent
  }, {
    path: "scope/pboek/intralab/:locatie",
    component: OverzichtScopeComponent
  }, {
    path: "recipient/pboek/intralab/:locatie",
    component: OverzichtRecipientComponent
  }, {
    path: "rapport/rboek/:versie/:locatie",
    component: OverzichtRboekRapportComponent
  }, {
    path: "rapport/eboek/internet",
    component: OverzichtEboekRapportComponent
  }, {
    path: "aanvraag/rboek/:versie/:locatie",
    component: OverzichtAanvraagComponent
  }
];

const adminRoutes: Routes = [
  {path: "admin", component: StorageComponent, data: {sidebar: false}},
  {path: "bijlage/:directory/:file", component: BijlageComponent, data: {sidebar: false}}
];

export const routes: Routes = [
  ...pboekRoutes,
  ...rboekRoutes,
  ...eboekRoutes,
  ...sidebarRoutes,
  ...overzichtRoutes,
  ...adminRoutes,
  {path: "**", component: RedirectComponent, data: {sidebar: false}}
];
