import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {PboekHistoriek} from "./pboek-historiek";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {PboekDataComponent} from "./pboek-data.component";
import {PboekHistoriekFooterComponent} from "./pboek-historiek-footer.component";

@Component({
  selector: "app-pboek-intranet-historiek",
  standalone: true,
  imports: [CommonModule, PaginaOphalenComponent, PboekDataComponent, PboekHistoriekFooterComponent],
  templateUrl: "./pboek-historiek.component.html"
})
export class PboekIntranetHistoriekComponent extends PboekHistoriek {
  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, "intranet");
  }
}
