export interface StorageFile {
  readonly path: string;
  readonly name: string;
  readonly size: number;
  readonly created: string;
  readonly owner: string;
  readonly group: string;
  readonly url: string;
}

export class StorageFileComparator {
  static compareByName(file1: StorageFile, file2: StorageFile): number {
    return file1.name.localeCompare(file2.name);
  }
}
