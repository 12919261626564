import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {Pboek} from "./pboek";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {PboekDataComponent} from "./pboek-data.component";
import {PboekFooterComponent} from "./pboek-footer.component";

@Component({
  selector: "app-pboek-internet",
  standalone: true,
  imports: [CommonModule, PaginaOphalenComponent, PboekDataComponent, PboekFooterComponent],
  templateUrl: "./pboek.component.html"
})
export class PboekInternetComponent extends Pboek {
  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, "internet");
  }
}
