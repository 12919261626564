export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBnhiF-ORdEIlD6bElZe3oWWh8UfaRq7bc",
    authDomain: "boekentoy.nexuzhealth.com",
    databaseURL: "https://boekentoy.firebaseio.com",
    projectId: "boekentoy",
    storageBucket: "boekentoy.appspot.com",
    messagingSenderId: "1091121153582",
    appId: "1:1091121153582:web:a4cfa1d1a29ece3d3281bf",
    measurementId: "G-CJPMS3MRE0"
  }
};
