import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {IntralabLegendeComponent} from "./intralab-legende.component";

@Component({
  selector: "app-extra",
  standalone: true,
  imports: [CommonModule, RouterModule, IntralabLegendeComponent],
  template: `
    <table width="100%" border="0" class="border" cellspacing="1" cellpadding="4">
      <tr>
        <td class="cell">
          <ng-container *ngIf="versie === 'intralab'">
            <app-intralab-legende></app-intralab-legende>
            <br/>
          </ng-container>
          <div class="extra">
            <b>Extra</b>
            <ul>
              <li *ngIf="type === 'pboek'">
                <a target="_blank" [href]="handleiding">handleiding</a>
              </li>
              <li *ngIf="type === 'pboek' && versie !== 'internet'">
                <a [routerLink]="['/collectiemateriaal/pboek', versie, locatie]">collectiemateriaal</a>
              </li>
              <ng-container *ngIf="type === 'pboek' && versie === 'intralab'">
                <li>overzicht per</li>
                <ul>
                  <li><a [routerLink]="['/testnaam/pboek/intralab', locatie]">testnaam</a></li>
                  <li><a [routerLink]="['/verantwoordelijke/pboek/intralab', locatie]">verantwoordelijke</a></li>
                  <li><a [routerLink]="['/formulier/pboek/intralab', locatie]">formulier</a></li>
                </ul>
                <li>rapport</li>
                <ul>
                  <li><a [routerLink]="['/globaal/pboek/intralab', locatie]">globaal</a></li>
                  <li><a [routerLink]="['/procedure/pboek/intralab', locatie]">procedures</a></li>
                  <li><a [routerLink]="['/frequentie/pboek/intralab', locatie]">frequenties</a></li>
                  <li><a [routerLink]="['/scope/pboek/intralab', locatie]">scope</a></li>
                  <li><a [routerLink]="['/recipient/pboek/intralab', locatie]">recipiënten</a></li>
                </ul>
              </ng-container>
              <ng-container *ngIf="type === 'pboek'">
                <li>per formulier en aanvraag</li>
                <ul>
                  <li><a [routerLink]="['/nietziv/pboek', versie, locatie]">niet-ZIV prijslijst</a></li>
                  <li><a [routerLink]="['/diagnoseregel/pboek', versie, locatie]">lijst diagnoseregels</a></li>
                </ul>
              </ng-container>
              <ng-container *ngIf="type === 'rboek' && versie !== 'internet'">
                <li><a [routerLink]="[{outlets: {sidebar: ['rboek-rapport']}}]">rapport</a></li>
              </ng-container>
              <ng-container *ngIf="type === 'eboek'">
                <li><a routerLink="/rapport/eboek/internet">rapport</a></li>
              </ng-container>
              <li>
                <a *ngIf="locatie; else tocGeenLocatie" [routerLink]="['/toc', type, versie, locatie]">inhoudstafel</a>
                <ng-template #tocGeenLocatie>
                  <a [routerLink]="['/toc', type, versie]">inhoudstafel</a>
                </ng-template>
              </li>
              <li *ngIf="type === 'pboek' && versie !== 'internet'">
                <a target="_blank" href="https://wiki.uz.kuleuven.ac.be/display/KWShelp/Labo+-+aanvraag+en+bijaanvraag">handleiding labo(bij-)aanvragen in KWS</a>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </table>
  `
})
export class ExtraComponent {
  @Input() type: BoekType;
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie | null;

  get handleiding(): string {
    switch (this.versie) {
      case "intranet":
      case "intralab":
        return "https://wiki/x/CQOp";
      case "internet":
        return "https://firebasestorage.googleapis.com/v0/b/uz-laboboeken.appspot.com/o/GHB_LAG%2Fpboek_internet_handleiding.pdf?alt=media";
      default:
        throw new Error("Onbekende versie: " + this.versie);
    }
  }
}
