import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {Rboek} from "./rboek";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {RboekDataComponent} from "./rboek-data.component";
import {RboekFooterComponent} from "./rboek-footer.component";

@Component({
  selector: "app-rboek-internet",
  standalone: true,
  imports: [CommonModule, PaginaOphalenComponent, RboekDataComponent, RboekFooterComponent],
  templateUrl: "./rboek.component.html"
})
export class RboekInternetComponent extends Rboek {
  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, "internet");
  }
}
