import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {of, Subject, Subscription} from "rxjs";
import {filter, switchMap} from "rxjs/operators";
import {StorageDirectories, StorageDirectory} from "../core/storage/storage-directory";
import {StorageFile, StorageFileComparator} from "../core/storage/storage-file";
import {StorageService} from "../core/storage/storage.service";

@Component({
  selector: "app-storage-list",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <ng-container *ngIf="directory$ | async as directory">
      <h4>Bijlage lijst</h4>
      <table>
        <thead>
        <tr>
          <th>naam</th>
          <th>grootte</th>
          <th>eigenaar</th>
          <th>toegevoegd op</th>
          <th>URL</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of files$ | async">
          <td>{{file.name}}</td>
          <td>{{toFileSizeString(file.size)}}</td>
          <td>{{file.owner}}</td>
          <td>{{file.created | date}}</td>
          <td><a target="_blank" [routerLink]="getURL(file.path, false)">{{getURL(file.path, true)}}</a></td>
          <td><button (click)="delete(file)">verwijderen</button></td>
        </tr>
        <tr *ngIf="message">
          <td colspan="6" [class]="messageClass">{{message}}</td>
        </tr>
        </tbody>
      </table>
    </ng-container>
  `,
  styleUrls: ["./storage.css"]
})
export class StorageListComponent implements OnInit, OnDestroy {
  private static FILE_SIZE_UNITS: ReadonlyArray<string> = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  directory$ = new Subject<StorageDirectory | null>();
  directorySubscription: Subscription;
  currentDirectory: StorageDirectory | null;
  files$ = new Subject<StorageFile[]>();
  message: string | null;
  warning: boolean;

  constructor(private readonly storage: StorageService) {
  }

  ngOnInit(): void {
    this.directorySubscription = this.directory$.pipe(
      switchMap((dir: StorageDirectory | null) => {
        this.files$.next([]);
        this.message = "Bijlagen ophalen...";
        this.warning = false;
        return dir ? this.storage.getFiles(StorageDirectories.toPath(dir)).catch(() => null) : of([]);
      })
    ).subscribe((files: StorageFile[] | null) => {
      if (!files) {
        this.message = "Geen toegang tot bijlagen";
        this.warning = true;
      } else if (!files.length) {
        this.message = "Geen bijlagen";
        this.warning = false;
      } else {
        files.sort(StorageFileComparator.compareByName);
        this.files$.next(files);
        this.message = null;
        this.warning = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.directorySubscription.unsubscribe();
  }

  @Input()
  set directory(directory: StorageDirectory | null) {
    this.directory$.next(directory);
    this.currentDirectory = directory;
  }

  toFileSizeString(size: number): string {
    for (const unit of StorageListComponent.FILE_SIZE_UNITS) {
      if (size < 1024 || unit === "YB") {
        const fixed: string = size.toFixed(Number.isInteger(size) ? 0 : 1);
        return `${fixed}\xA0${unit}`;
      }
      size /= 1024;
    }
    throw new Error("Bestandsgrootte kan niet berekend worden: " + size);
  }

  getURL(filePath: string, full: boolean): string {
    const url = `/bijlage/${filePath}`;
    return full ? `${window.location.protocol}//${window.location.host}${url}` : url;
  }

  delete(file: StorageFile): void {
    if (confirm(`Weet u zeker dat u bijlage "${file.name}" definitief wilt verwijderen?`)) {
      this.storage.deleteFile(file.path)
          .then(() => this.refresh())
          .catch(() => alert(`Bijlage "${file.name}" kan niet verwijderd worden`));
    }
  }

  refresh(): void {
    this.directory = this.currentDirectory;
  }

  get messageClass(): string {
    return this.warning ? "warning" : "info";
  }
}
