import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-diagnose-regel",
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngFor="let r of element.diagnoseRegel; last as last">
      {{r.omschrijving}}
      <hr *ngIf="!last"/>
    </ng-container>
  `
})
export class PboekDiagnoseRegelComponent {
  @Input() element: any;
}
