import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {User} from "@angular/fire/auth";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AuthenticationService} from "../core/authentication.service";
import {StorageDirectory, StorageDirectories} from "../core/storage/storage-directory";
import {LoginComponent} from "./login.component";
import {StorageListComponent} from "./storage-list.component";
import {StorageUploadComponent} from "./storage-upload.component";

@Component({
  selector: "app-storage",
  standalone: true,
  imports: [CommonModule, LoginComponent, StorageListComponent, StorageUploadComponent],
  template: `
    <app-login></app-login>
    <ng-container *ngIf="user$ | async as user">
      <h2>Bijlagen overzicht</h2>
      <h4>Bijlage map</h4>
      <select #directorySelect (change)="setSelectedDirectory(directorySelect.value)">
        <option disabled selected value=""> -- kies een map -- </option>
        <option *ngFor="let directory of directories">{{directory}}</option>
      </select>
      <app-storage-upload [directory]="selectedDirectory" (uploaded)="list.refresh()"></app-storage-upload>
      <app-storage-list #list [directory]="selectedDirectory"></app-storage-list>
    </ng-container>
  `
})
export class StorageComponent implements OnInit {
  directories: readonly StorageDirectory[] = StorageDirectories.CURRENT;
  selectedDirectory: StorageDirectory | null;
  user$: Observable<User | null>;

  constructor(private readonly auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.user$ = this.auth.user.pipe(
      tap(user => {
        if (!user) {
          this.selectedDirectory = null;
        }
      })
    );
  }

  setSelectedDirectory(dir: string): void {
    this.selectedDirectory = dir as StorageDirectory;
  }
}
