import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Observable} from "rxjs";
import {PaginaOphalenStatus} from "./pagina-ophalen-status";
import {PaginaService} from "../../core/pagina.service";
import {PageNotFoundComponent} from "./page-not-found.component";

@Component({
  selector: "app-pagina-ophalen",
  standalone: true,
  imports: [CommonModule, PageNotFoundComponent],
  template: `
    <ng-container [ngSwitch]="status$ | async">
      <p *ngSwitchCase="PaginaOphalenStatus.TODO">Pagina zoeken...</p>
      <p *ngSwitchCase="PaginaOphalenStatus.BUSY">Pagina ophalen...</p>
      <ng-content *ngSwitchCase="PaginaOphalenStatus.SUCCEEDED"></ng-content>
      <app-page-not-found *ngSwitchCase="PaginaOphalenStatus.FAILED"></app-page-not-found>
      <app-page-not-found *ngSwitchDefault></app-page-not-found>
    </ng-container>
  `
})
export class PaginaOphalenComponent implements OnInit {
  PaginaOphalenStatus = PaginaOphalenStatus;
  status$: Observable<PaginaOphalenStatus>;

  constructor(private readonly pagina: PaginaService) {
  }

  ngOnInit(): void {
    this.status$ = this.pagina.status;
  }
}
