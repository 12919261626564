import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-riziv",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngFor="let r of element.riziv; last as last">
      {{r.nomenclatuurnr}}
      <hr *ngIf="!last"/>
    </div>
  `
})
export class PboekRIZIVComponent {
  @Input() element: any;
}
