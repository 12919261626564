import {Component, Input, inject} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StorageService} from "../core/storage/storage.service";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekURL} from "../shared/boek/boek-url";

@Component({
  selector: "app-pboek-collectiemateriaal",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngFor="let c of element.collectiemateriaal; last as last" [class]="c.plaats">
      <b *ngIf="c.aantal > 1"><i>{{c.aantal}}x </i></b>
      <b *ngIf="element.collectiemateriaal.length > 1 && c.naam">{{c.naam}}</b>
      <br *ngIf="c.aantal > 1 || (element.collectiemateriaal.length > 1 && c.naam)"/>
      <ng-container *ngFor="let a of c.collectiemateriaalAlternatief; last as last">
        {{a.naam}} 
        <ng-container *ngIf="getFoto(a) | async as foto; else geenFoto">
          (<a [href]="foto" target="_blank">{{a.afkorting}}</a>)
        </ng-container>
        <ng-template #geenFoto>({{a.afkorting}})</ng-template>
        <ng-container *ngIf="!last">, </ng-container>
      </ng-container>
      <hr *ngIf="!last"/>
    </div>
  `
})
export class PboekCollectiemateriaalComponent {
  @Input() element: any;
  @Input() locatie: BoekLocatie;
  private readonly storage = inject(StorageService);
  private readonly fotoCache = new Map<string, Promise<string>>();

  getFoto(collectiemateriaal: any): Promise<string> {
    const afkorting: string = collectiemateriaal.afkorting;
    let foto$: Promise<string> | undefined = this.fotoCache.get(afkorting);
    if (!foto$) {
      foto$ = BoekURL.getCollectiemateriaalFoto(this.storage, this.locatie, afkorting);
      this.fotoCache.set(afkorting, foto$);
    }
    return foto$;
  }
}
