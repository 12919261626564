import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, RouterModule} from "@angular/router";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {OverzichtPboekRapport} from "./overzicht-pboek-rapport";
import {RapportRij} from "./rapport-rij";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-globaal",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      <ng-container *ngIf="tabel$ | async as tabel">
        <app-export-table [table]="exportTable" filename="globaal"></app-export-table>
        <table #exportTable class="border">
          <caption>Globaal</caption>
          <tr>
            <th class="cell" colspan="6"></th>
            <th *ngFor="let titel of afnameInstructieTitels" class="cell">procedure</th>
            <th class="cell" colspan="8"></th>
          </tr>
          <tr>
            <th class="header">nummer</th>
            <th class="cell">testnaam</th>
            <th class="cell">synoniem</th>
            <th class="cell">afnamemateriaal</th>
            <th class="cell">collectiemateriaal</th>
            <th class="cell">verantwoordelijke</th>
            <th *ngFor="let titel of afnameInstructieTitels" class="cell">{{titel}}</th>
            <th class="cell">diagnose regel(s)</th>
            <th class="cell">niet ZIV prijs</th>
            <th class="cell">riziv nomenclatuurnummer</th>
            <th class="cell">aanvraagnummer</th>
            <th class="cell">formuliernummer</th>
            <th class="cell">uitvoerder</th>
            <th class="cell">uitvoerfrequentie</th>
            <th class="cell">antwoordtijd</th>
          </tr>
          <tr *ngFor="let rij of tabel">
            <td class="header code-field">
              <a [routerLink]="['/', type, versie, locatie, rij.entiteitCode]">{{rij.entiteitCode}}</a>
            </td>
            <td class="cell">{{rij.entiteitOmschrijving}}</td>
            <td class="cell">
              <ng-container *ngFor="let synoniem of rij.synoniemen; last as last">
                {{synoniem}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let afnameMateriaal of rij.afnameMaterialen; last as last">
                {{afnameMateriaal}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let collectiemateriaal of rij.collectiematerialen; last as last">
                {{collectiemateriaal}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let verantwoordelijke of rij.verantwoordelijken; last as last">
                <a target="_blank" [href]="verantwoordelijke.wieIsWie">{{verantwoordelijke.naam}}</a>
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td *ngFor="let titel of afnameInstructieTitels" class="cell">
              <ng-container *ngFor="let afnameInstructie of rij.afnameInstructiesMap.get(titel); last as last">
                <a *ngIf="afnameInstructie.isHref; else notHref" target="_blank" [href]="afnameInstructie.text">
                  {{afnameInstructie.text}}
                </a>
                <ng-template #notHref>{{afnameInstructie.text}}</ng-template>
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let diagnoseRegel of rij.diagnoseRegels; last as last">
                {{diagnoseRegel}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">{{rij.nietZIVPrijs | currency:"EUR"}}</td>
            <td class="cell">
              <ng-container *ngFor="let riziv of rij.rizivs; last as last">
                {{riziv}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let aanvraagNummer of rij.aanvraagNummers; last as last">
                {{aanvraagNummer}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let formulierNummer of rij.formulierNummers; last as last">
                {{formulierNummer}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let uitvoerendLabo of rij.uitvoerendeLabos; last as last">
                {{uitvoerendLabo}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let uitvoerfrequentie of rij.uitvoerfrequenties; last as last">
                {{uitvoerfrequentie}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td class="cell">
              <ng-container *ngFor="let uitvoeringstijd of rij.uitvoeringstijden; last as last">
                {{uitvoeringstijd}}
                <br *ngIf="!last"/>
              </ng-container>
            </td>
          </tr>
        </table>
      </ng-container>
    </app-pagina-ophalen>
  `,
  styleUrls: ["./overzicht.css"]
})
export class OverzichtGlobaalComponent extends OverzichtPboekRapport {
  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, entiteit => {
      return new RapportRij(entiteit.code,
        entiteit.omschrijving,
        this.getSynoniemen(entiteit),
        this.getAfnameMaterialen(entiteit),
        this.getCollectiematerialen(entiteit),
        this.getVerantwoordelijken(entiteit),
        this.getAfnameInstructiesMap(entiteit),
        this.getDiagnoseRegels(entiteit),
        this.getNietZIVPrijs(entiteit),
        this.getRIZIVs(entiteit),
        this.getAanvraagNummers(entiteit),
        this.getFormulierNummers(entiteit),
        this.getUitvoerendeLabos(entiteit),
        this.getUitvoerfrequenties(entiteit),
        this.getUitvoeringstijden(entiteit));
    });
  }
}
