import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-niet-ziv-prijs",
  standalone: true,
  imports: [CommonModule],
  template: `
    {{element.nietZIVPrijs | currency:"EUR"}}
  `
})
export class PboekNietZIVPrijsComponent {
  @Input() element: any;
}
