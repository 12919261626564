import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {EboekHistoriek} from "./eboek-historiek";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {EboekDataComponent} from "./eboek-data.component";
import {EboekHistoriekFooterComponent} from "./eboek-historiek-footer.component";

@Component({
  selector: "app-eboek-internet-historiek",
  standalone: true,
  imports: [CommonModule, PaginaOphalenComponent, EboekDataComponent, EboekHistoriekFooterComponent],
  templateUrl: "./eboek-historiek.component.html"
})
export class EboekInternetHistoriekComponent extends EboekHistoriek {
  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, "internet");
  }
}
