import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-synoniem",
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngFor="let s of element.synoniem; last as last">
      {{s.omschrijving}}<ng-container *ngIf="!last">; </ng-container>
    </ng-container>
  `
})
export class PboekSynoniemComponent {
  @Input() element: any;
}
