import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-uitvoerend-labo",
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngFor="let l of element.uitvoerendLabo">
      {{l.naam}}
      <br/>
    </ng-container>
  `
})
export class PboekUitvoerendLaboComponent {
  @Input() element: any;
}
