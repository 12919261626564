import {environment} from "../../../environments/environment";

const prodDirs = ["GHB LAG", "GHB CME", "GHB PO", "DIEST"] as const;
const devDirs = ["TEST 1", "TEST 2", "GEEN TOEGANG"] as const;
const allDirs = [...prodDirs, ...devDirs] as const;
export type StorageDirectory = typeof allDirs[number];

export class StorageDirectories {
  static readonly PROD: readonly StorageDirectory[] = StorageDirectories.toSorted(prodDirs);
  static readonly DEV: readonly StorageDirectory[] = StorageDirectories.toSorted(devDirs);
  static readonly CURRENT: readonly StorageDirectory[]
      = environment.production ? StorageDirectories.PROD : StorageDirectories.DEV;

  private constructor() {
  }

  private static toSorted(dirs: readonly StorageDirectory[]): StorageDirectory[] {
    return [...dirs].sort();
  }

  static toPath(dir: StorageDirectory, file?: string): string {
    let path = dir.replace(/ /g, "_");
    if (file) {
      path += `/${file}`;
    }
    return path;
  }
}
