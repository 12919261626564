import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, RouterModule} from "@angular/router";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {OverzichtPboekRapport} from "./overzicht-pboek-rapport";
import {RapportRij} from "./rapport-rij";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-procedure",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      <ng-container *ngIf="tabel$ | async as tabel">
        <app-export-table [table]="exportTable" filename="procedures"></app-export-table>
        <table #exportTable class="border">
          <caption>Procedures</caption>
          <tr>
            <th class="cell" colspan="3"></th>
            <th *ngFor="let titel of afnameInstructieTitels" class="cell">procedure</th>
          </tr>
          <tr>
            <th class="header">nummer</th>
            <th class="cell">testnaam</th>
            <th class="cell">verantwoordelijke</th>
            <th *ngFor="let titel of afnameInstructieTitels" class="cell">{{titel}}</th>
          </tr>
          <tr *ngFor="let rij of tabel">
            <td class="header code-field">
              <a [routerLink]="['/', type, versie, locatie, rij.entiteitCode]">{{rij.entiteitCode}}</a>
            </td>
            <td class="cell">{{rij.entiteitOmschrijving}}</td>
            <td class="cell">
              <ng-container *ngFor="let verantwoordelijke of rij.verantwoordelijken; last as last">
                <a target="_blank" [href]="verantwoordelijke.wieIsWie">{{verantwoordelijke.naam}}</a>
                <br *ngIf="!last"/>
              </ng-container>
            </td>
            <td *ngFor="let titel of afnameInstructieTitels" class="cell">
              <ng-container *ngFor="let afnameInstructie of rij.afnameInstructiesMap.get(titel); last as last">
                <a *ngIf="afnameInstructie.isHref; else notHref" target="_blank" [href]="afnameInstructie.text">
                  {{afnameInstructie.text}}
                </a>
                <ng-template #notHref>{{afnameInstructie.text}}</ng-template>
                <br *ngIf="!last"/>
              </ng-container>
            </td>
          </tr>
        </table>
      </ng-container>
    </app-pagina-ophalen>
  `,
  styleUrls: ["./overzicht.css"]
})
export class OverzichtProcedureComponent extends OverzichtPboekRapport {
  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, entiteit => {
      return new RapportRij(entiteit.code,
        entiteit.omschrijving,
        [],
        [],
        [],
        this.getVerantwoordelijken(entiteit),
        this.getAfnameInstructiesMap(entiteit),
        [],
        null,
        [],
        [],
        [],
        [],
        [],
        []);
    });
  }
}
