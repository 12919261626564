import {AsciiTranslator} from "./ascii-translator";
import {Translator} from "./translator";

export class CanonicalTranslator implements Translator {
  private readonly ascii = new AsciiTranslator();

  translate(s: string, removeSpaces: boolean): string {
    let buffer = this.ascii.translate(s, false);
    buffer = buffer.replace(/\W/g," ");
    buffer = buffer.replace(/\s+/g," ");
    if (removeSpaces) {
      buffer = buffer.replace(/ /g, "");
    }
    return buffer;
  }
}
