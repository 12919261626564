import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap, RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-diagnose-regel",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      <ng-container *ngIf="entiteiten$ | async as entiteiten">
        <app-export-table [table]="table" filename="diagnoseregels"></app-export-table>
        <table #table class="border">
          <caption>Lijst diagnoseregels</caption>
          <tr>
            <th class="cell">formulier</th>
            <th class="cell">aanvraag</th>
            <th class="cell">testnaam</th>
            <th class="cell">diagnoseregels</th>
          </tr>
          <ng-container *ngFor="let entiteit of entiteiten">
            <tr *ngIf="getDiagnoseRegels(entiteit) as diagnoseRegels">
              <td class="cell code-field">{{getFormulierNummer(entiteit)}}</td>
              <td class="cell code-field">{{getAanvraagNummer(entiteit)}}</td>
              <td class="cell">
                <a [routerLink]="['/', type, versie, locatie, entiteit.code]">{{entiteit.omschrijving}}</a>
              </td>
              <td class="cell">
                <ng-container *ngFor="let diagnoseRegel of diagnoseRegels; last as last">
                  {{diagnoseRegel}}
                  <br *ngIf="!last"/>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </table>
      </ng-container>
    </app-pagina-ophalen>
  `,
  styleUrls: ["./overzicht.css"]
})
export class OverzichtDiagnoseRegelComponent implements Overzicht, OnInit {
  readonly type: BoekType = "pboek";

  entiteiten$: Observable<any[]>;
  versie: BoekVersie;
  locatie: BoekLocatie;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entiteiten$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any[]> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const versie = BoekTools.getVersie(params);
        if (!versie) {
          throw new Error("Versie is vereist");
        }
        this.versie = versie;
        const locatie = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map(entiteiten => entiteiten.sort(this.compareEntiteiten.bind(this)))
        );
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  getDiagnoseRegels(entiteit: any): string[] | null {
    for (const element of entiteit.element) {
      if (element.type === "diagnoseRegel") {
        return element.diagnoseRegel.map((regel: any): string => regel.omschrijving);
      }
    }
    return null;
  }

  getFormulierNummer(entiteit: any): number | null {
    const aanvraag: any | null = this.getAanvraag(entiteit);
    const formulieren: any[] = aanvraag ? aanvraag.formulier : [];
    return formulieren.length ? formulieren[0].nummer : null;
  }

  getAanvraagNummer(entiteit: any): number | null {
    const aanvraag: any | null = this.getAanvraag(entiteit);
    return aanvraag ? aanvraag.nummer : null;
  }

  private getAanvraag(entiteit: any): any | null {
    for (const element of entiteit.element) {
      if (element.type === "aanvraag") {
        return element.aanvraag[0];
      }
    }
    return null;
  }

  private compareEntiteiten(e1: any, e2: any): number {
    const formulierNummer1: number = this.getFormulierNummer(e1) || Number.MAX_VALUE;
    const formulierNummer2: number = this.getFormulierNummer(e2) || Number.MAX_VALUE;
    let cmp = formulierNummer1 - formulierNummer2;
    if (cmp !== 0) {
      return cmp;
    }
    const aanvraagNummer1: number = this.getAanvraagNummer(e1) || Number.MAX_VALUE;
    const aanvraagNummer2: number = this.getAanvraagNummer(e2) || Number.MAX_VALUE;
    cmp = aanvraagNummer1 - aanvraagNummer2;
    if (cmp !== 0) {
      return cmp;
    }
    const omschrijving1: string = e1.omschrijving;
    const omschrijving2: string = e2.omschrijving;
    return omschrijving1.localeCompare(omschrijving2);
  }
}
