<app-pagina-ophalen>
  <div *ngIf="entiteit$ | async as entiteit" class="historiek">
    <app-eboek-data *ngIf="!entiteit.verwijderd; else verwijderd" [entiteit]="entiteit">
    </app-eboek-data>
    <ng-template #verwijderd>
      <h2>Pagina werd verwijderd</h2>
    </ng-template>
    <app-eboek-historiek-footer [versie]="versie" [code]="code" [datum]="datum"
                                [historiek$]="historiek$">
    </app-eboek-historiek-footer>
  </div>
</app-pagina-ophalen>
