import {Injectable} from "@angular/core";
import * as XLSX from "xlsx";
import {WorkBook, WorkSheet} from "xlsx";

@Injectable({
  providedIn: "root"
})
export class ExporterService {
  private static readonly XLSX = ".xlsx";

  /**
   * Exporteer een tabel naar een XLSX bestand.
   */
  exportTableToXLSX(table: HTMLTableElement, filename: string): void {
    filename = this.appendExtension(filename, ExporterService.XLSX);
    const workbook: WorkBook = XLSX.utils.table_to_book(table, {raw: true});
    XLSX.writeFile(workbook, filename);
  }

  /**
   * Exporteer meerdere tabellen naar een XLSX bestand,
   * waarbij elke tabel een aparte sheet heeft.
   */
  exportTablesToXLSX(tables: [HTMLTableElement, string][], filename: string): void {
    filename = this.appendExtension(filename, ExporterService.XLSX);
    const workbook: WorkBook = XLSX.utils.book_new();
    for (const pair of tables) {
      const table: HTMLTableElement = pair[0];
      const sheetName: string = pair[1];
      const sheet: WorkSheet = XLSX.utils.table_to_sheet(table);
      XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
    }
    XLSX.writeFile(workbook, filename);
  }

  private appendExtension(filename: string, extension: string): string {
    return filename.toLowerCase().endsWith(extension.toLocaleLowerCase()) ? filename : filename + extension;
  }
}
