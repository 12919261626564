<app-pagina-ophalen>
  <div *ngIf="entiteit$ | async as entiteit" class="historiek">
    <app-pboek-data *ngIf="!entiteit.verwijderd; else verwijderd" [entiteit]="entiteit" [versie]="versie"
                    [locatie]="locatie">
    </app-pboek-data>
    <ng-template #verwijderd>
      <h2>Pagina werd verwijderd</h2>
    </ng-template>
    <app-pboek-historiek-footer [versie]="versie" [locatie]="locatie" [code]="code" [datum]="datum"
                                [historiek$]="historiek$">
    </app-pboek-historiek-footer>
  </div>
</app-pagina-ophalen>
