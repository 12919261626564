import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {User} from "@angular/fire/auth";
import {Observable} from "rxjs";
import {AuthenticationService} from "../core/authentication.service";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngIf="user$ | async as user; else authenticate">
      Gebruiker: {{user.displayName}}
      <button (click)="logout()">Logout</button>
    </div>
    <ng-template #authenticate>
      <div>
        <button (click)="login()">Inloggen met Cumulus account</button>
      </div>
    </ng-template>
  `
})
export class LoginComponent implements OnInit {
  user$: Observable<User | null>;

  constructor(private readonly auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.user$ = this.auth.user;
  }

  login(): void {
    this.auth.login();
  }

  logout(): void {
    this.auth.logout();
  }
}
