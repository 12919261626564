import {Injectable} from "@angular/core";
import {Auth, User, user, signInWithPopup, GoogleAuthProvider} from "@angular/fire/auth";
import {Observable} from "rxjs";
import {UserCredential} from "firebase/auth";
import {LoggerService} from "./logger.service";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  private _snapshot = AuthenticationServiceSnapshot.EMPTY;

  constructor(private readonly logger: LoggerService,
              private readonly auth: Auth) {
  }

  get snapshot(): AuthenticationServiceSnapshot {
    return this._snapshot;
  }

  get user(): Observable<User | null> {
    return user(this.auth);
  }

  login(): Promise<User> {
    const promise: Promise<User> = signInWithPopup(this.auth, new GoogleAuthProvider())
        .then((credential: UserCredential) => credential.user);
    promise
        .then(user => {
          this.logger.info("auth: login", user.displayName);
          this._snapshot = AuthenticationServiceSnapshot.of(user);
        })
        .catch(err => {
          this.logger.error("auth: login failed:", err.message);
          this._snapshot = AuthenticationServiceSnapshot.EMPTY;
        });
    return promise;
  }

  logout(): Promise<void> {
    const promise: Promise<void> = this.auth.signOut();
    promise
        .then(() => this.logger.info("auth: logout"))
        .catch(err => this.logger.error("auth: logout failed:", err.message));
    return promise;
  }
}

export class AuthenticationServiceSnapshot {
  static readonly EMPTY = new AuthenticationServiceSnapshot(null);

  private constructor(readonly user: User | null) {
  }

  static of(user: User | null): AuthenticationServiceSnapshot {
    return user ? new AuthenticationServiceSnapshot(user) : this.EMPTY;
  }
}
