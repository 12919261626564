import {Directive, OnInit} from "@angular/core";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";
import {BoekHistoriek} from "../shared/boek/boek-historiek";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {HistoriekEntry} from "../core/historiek-entry";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";

@Directive()
export abstract class RboekHistoriek implements BoekHistoriek, OnInit {
  readonly type: BoekType = "rboek";

  entiteit$: Observable<any | null>;
  historiek$: Observable<HistoriekEntry[]>;
  locatie: BoekLocatie;
  code: number;
  datum: string;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute,
              readonly versie: BoekVersie) {
  }

  ngOnInit(): void {
    this.entiteit$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const locatie: BoekLocatie | null = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        const code: number | null = BoekTools.getCode(params);
        if (!code) {
          throw new Error("Code is vereist");
        }
        this.code = code;
        const datum: string | null = params.get("datum");
        if (!datum) {
          throw new Error("Datum is vereist");
        }
        this.datum = datum;
        this.historiek$ = this.db.getHistoriekOverzicht(this.type, this.versie, this.locatie, this.code);
        return this.db.getHistoriekEntiteit(this.type, this.versie, this.locatie, this.code, this.datum);
      }),
      tap((entiteit: any | null) =>
        this.pagina.setStatus(entiteit ? PaginaOphalenStatus.SUCCEEDED : PaginaOphalenStatus.FAILED)
      ),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of(null);
      })
    );
  }
}
