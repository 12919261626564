import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EboekStaalComponent} from "./eboek-staal.component";

@Component({
  selector: "app-eboek-data",
  standalone: true,
  imports: [CommonModule, EboekStaalComponent],
  template: `
    <table cellpadding="4" cellspacing="1" class="border" border="0" width="100%">
      <tr>
        <td colspan="2" class="header">{{entiteit.omschrijving}}</td>
      </tr>
      <tr *ngIf="entiteit.synoniem?.length">
        <td class="cell" style="vertical-align:top;">
          <ng-container *ngFor="let synoniem of entiteit.synoniem; last as last">
            {{synoniem.omschrijving}}<ng-container *ngIf="!last">; </ng-container>
          </ng-container>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="cell">
          <app-eboek-staal [stalen]="entiteit.staal"></app-eboek-staal>
        </td>
      </tr>
    </table>
  `
})
export class EboekDataComponent {
  @Input() entiteit: any;
}
