import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {Observable} from "rxjs";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {HistoriekEntry} from "../core/historiek-entry";

@Component({
  selector: "app-pboek-historiek-footer",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <table *ngIf="historiek$ | async as historiek" width="100%">
      <tr>
        <td class="footer historiek-data">
          <div>historiek</div>
          <ng-container *ngFor="let entry of historiek; first as first">
            <b *ngIf="entry.datum === datum; else notDatum">{{entry.datum}}</b>
            <ng-template #notDatum>
              <a *ngIf="first; else notFirst" [routerLink]="getEntiteitLink()">{{entry.datum}}</a>
              <ng-template #notFirst>
                <ng-container *ngIf="entry.verwijderd; else notVerwijderd">
                  {{entry.datum}} (verwijderd)
                </ng-container>
                <ng-template #notVerwijderd>
                  <ng-container *ngIf="entry.prehistorie; else notPrehistorie">
                    <a *ngIf="versie !== 'internet'; else isInternet" [href]="getHistoriekLink(entry)" target="_blank">
                      {{entry.datum}}
                    </a>
                    <ng-template #isInternet>{{entry.datum}}</ng-template>
                  </ng-container>
                  <ng-template #notPrehistorie>
                    <a [routerLink]="getHistoriekLink(entry)">{{entry.datum}}</a>
                  </ng-template>
                </ng-template>
              </ng-template>
            </ng-template>
            <br/>
          </ng-container>
        </td>
      </tr>
    </table>
  `
})
export class PboekHistoriekFooterComponent {
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie;
  @Input() code: number;
  @Input() datum: string;
  @Input() historiek$: Observable<HistoriekEntry[]>;

  getEntiteitLink(): string {
    return BoekURL.getEntiteitLink("pboek", this.versie, this.locatie, this.code);
  }

  getHistoriekLink(entry: HistoriekEntry): string {
    return BoekURL.getHistoriekLink("pboek", this.versie, this.locatie, this.code, entry.datum, entry.prehistorie);
  }
}
