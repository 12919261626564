import {Component, EventEmitter, Input, OnInit, Output, NgZone} from "@angular/core";
import {CommonModule} from "@angular/common";
import {User} from "@angular/fire/auth";
import {Observable, Subject} from "rxjs";
import {UploadTask, UploadTaskSnapshot} from "firebase/storage";
import {AuthenticationService} from "../core/authentication.service";
import {StorageDirectories, StorageDirectory} from "../core/storage/storage-directory";
import {StorageService} from "../core/storage/storage.service";

@Component({
  selector: "app-storage-upload",
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngIf="user$ | async as user">
      <ng-container *ngIf="directory$ | async as directory">
        <h4>Bijlage uploaden</h4>
        <input #uploadInput (change)="setUploadData(uploadInput, directory)" type="file" [disabled]="uploadTask"/><br/>
        <ng-container *ngIf="file$ | async as file">
          <ng-container *ngIf="uploadTask; else showStartUpload">
            <button (click)="stopUpload()">Upload annuleren</button>
            <span *ngIf="percentageChanges$ | async as percentageChanges">
              {{percentageChanges | number:"1.0-0"}}% voltooid
            </span>
          </ng-container>
          <ng-template #showStartUpload>
            <button (click)="startUpload(file, directory, user)" [disabled]="checkingUploadExists">Uploaden</button>
            <ng-container *ngIf="uploadExists$ | async as uploadExists">
              <span *ngIf="uploadExists" class="warning">
                Opgelet: de bestaande bijlage zal overschreven worden!
              </span>
            </ng-container>
          </ng-template>
        </ng-container>
        <div class="warning" [class.hidden]="warningHidden">{{warning}}</div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./storage.css"]
})
export class StorageUploadComponent implements OnInit {
  @Output() uploaded = new EventEmitter<void>();
  user$: Observable<User | null>;
  directory$ = new Subject<StorageDirectory | null>();
  file$ = new Subject<File | null>();
  private uploadInput: any;
  checkingUploadExists: boolean;
  uploadExists$: Promise<boolean> | null;
  uploadTask: UploadTask | null;
  percentageChanges$ = new Subject<number | null>();
  warning: string | null;
  warningHidden = true;

  constructor(private readonly auth: AuthenticationService,
              private readonly storage: StorageService,
              private readonly zone: NgZone) {
  }

  ngOnInit(): void {
    this.user$ = this.auth.user;
  }

  @Input()
  set directory(directory: StorageDirectory | null) {
    this.directory$.next(directory);
    this.setUploadData(this.uploadInput, directory);
  }

  setUploadData(uploadInput: any | null, directory: StorageDirectory | null): void {
    this.uploadInput = uploadInput;
    const file: File | null = uploadInput && uploadInput.value ? uploadInput.files[0] : null;
    this.file$.next(file);
    if (file && directory) {
      this.checkingUploadExists = true;
      const path: string = StorageDirectories.toPath(directory, file.name);
      this.uploadExists$ = this.storage.getFile(path)
          .then(() => true)
          .catch(() => false)
          .finally(() => this.checkingUploadExists = false);
    } else {
      this.uploadExists$ = null;
      this.checkingUploadExists = false;
    }
  }

  startUpload(file: File, directory: StorageDirectory, user: User): void {
    if (this.uploadTask) {
      throw new Error("Kan maar 1 bijlage tegelijk uploaden");
    }
    const userName: string | null = user.displayName;
    if (!userName) {
      throw new Error("Gebruikersnaam is vereist");
    }
    const path: string = StorageDirectories.toPath(directory, file.name);
    this.uploadTask = this.storage.uploadFile(path, file, userName, directory);
    this.uploadTask
      .then(() => {
        this.uploaded.emit();
        this.resetUpload();
        this.warning = null;
      })
      .catch(err => {
        this.resetUpload();
        this.warning = "Upload gefaald: ";
        switch (err.code) {
          case "storage/canceled":
            this.warning += "upload werd geannuleerd";
            break;
          case "storage/unauthorized":
            this.warning += `geen toegang tot bijlage map '${directory}'`;
            break;
          default:
            this.warning += `onbekende fout: ${err.code}`;
            break;
        }
        this.warningHidden = false;
        setTimeout(() => this.warningHidden = true, 1000);
      });
    this.uploadTask.on('state_changed', (snapshot: UploadTaskSnapshot) => {
      this.zone.run(() => this.percentageChanges$.next(snapshot.bytesTransferred / snapshot.totalBytes * 100));
    });
  }

  stopUpload(): void {
    if (!this.uploadTask) {
      throw new Error("Geen bijlage aan het uploaden");
    }
    this.uploadTask.cancel();
    this.resetUpload();
  }

  private resetUpload(): void {
    this.file$.next(null);
    this.uploadInput.value = "";
    this.checkingUploadExists = false;
    this.uploadExists$ = null;
    this.uploadTask = null;
    this.percentageChanges$.next(null);
  }
}
