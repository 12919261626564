import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {Observable} from "rxjs";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {HistoriekEntry} from "../core/historiek-entry";

@Component({
  selector: "app-eboek-footer",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <table *ngIf="historiek$ | async as historiek" width="100%">
      <tr>
        <td class="footer boeken">
          Aanvraagnummer {{aanvraagNummer}}
        </td>
        <td class="footer historiek-data">
          <div>historiek</div>
          <ng-container *ngFor="let entry of historiek; first as first">
            <ng-container *ngIf="!first">
              <ng-container *ngIf="entry.verwijderd; else notVerwijderd">
                {{entry.datum}} (verwijderd)
              </ng-container>
              <ng-template #notVerwijderd>
                <ng-container *ngIf="entry.prehistorie; else notPrehistorie">
                  <a *ngIf="versie !== 'internet'; else isInternet" [href]="getHistoriekLink(entry)" target="_blank">
                    {{entry.datum}}
                  </a>
                  <ng-template #isInternet>{{entry.datum}}</ng-template>
                </ng-container>
                <ng-template #notPrehistorie>
                  <a [routerLink]="getHistoriekLink(entry)">{{entry.datum}}</a>
                </ng-template>
              </ng-template>
              <br/>
            </ng-container>
          </ng-container>
        </td>
        <td class="footer datum">
          <ng-container *ngIf="historiek.length">{{historiek[0].datum}}</ng-container>
        </td>
      </tr>
    </table>
  `
})
export class EboekFooterComponent {
  @Input() versie: BoekVersie;
  @Input() code: number;
  @Input() aanvraagNummer: number | null;
  @Input() historiek$: Observable<HistoriekEntry[]>;

  getHistoriekLink(entry: HistoriekEntry): string {
    return BoekURL.getHistoriekLink("eboek", this.versie, null, this.code, entry.datum, entry.prehistorie);
  }
}
