import {CanonicalTranslator} from "./canonical-translator";
import {Translator} from "./translator";

export class FuzzyTranslator implements Translator {
  private readonly canonical = new CanonicalTranslator();

  translate(s: string, removeSpaces: boolean): string {
    let buffer = this.canonical.translate(s, false);
    buffer = buffer.replace(/c([i|e|y])/gi,"s$1");
    buffer = buffer.replace(/c([^i|e|y])/gi,"k$1");
    buffer = buffer.replace(/y/gi,"i");
    buffer = buffer.replace(/th/gi,"t");
    buffer = buffer.replace(/ph/gi,"f");
    if (removeSpaces) {
      buffer = buffer.replace(/ /g,"");
    }
    return buffer;
  }
}
