import {ParamMap} from "@angular/router";
import {BoekLabo} from "./boek-labo";
import {BoekLocatie, DatabaseBoekLocatie} from "./boek-locatie";
import {BoekType} from "./boek-type";
import {BoekVersie} from "./boek-versie";

export class BoekTools {
  private static readonly TYPES: ReadonlyArray<string> = ["pboek", "rboek", "eboek"];
  private static readonly VERSIES: ReadonlyArray<string> = ["intranet", "internet", "intralab"];
  private static readonly LOCATIES: ReadonlyArray<string> = ["GHB", "DIEST"];

  private constructor() {
  }

  static getType(arg: ParamMap | string): BoekType | null {
    const type: string | null = (typeof arg === "string") ? arg : arg.get("type");
    if (!type) {
      return null;
    }
    if (!this.isType(type)) {
      throw new Error("Ongeldig type: " + type);
    }
    return type;
  }

  static isType(type: string): type is BoekType {
    return this.TYPES.includes(type);
  }

  static getVersie(arg: ParamMap | string): BoekVersie | null {
    const versie: string | null = (typeof arg === "string") ? arg : arg.get("versie");
    if (!versie) {
      return null;
    }
    if (!this.isVersie(versie)) {
      throw new Error("Ongeldige versie: " + versie);
    }
    return versie;
  }

  static isVersie(versie: string): versie is BoekVersie {
    return this.VERSIES.includes(versie);
  }

  static getLocatie(arg: ParamMap | string): BoekLocatie | null {
    const locatieOfCme: string | null = (typeof arg === "string") ? arg : arg.get("locatie");
    const locatie: string | null = locatieOfCme === "GHB CME" ? "GHB" : locatieOfCme;
    if (!locatie) {
      return null;
    }
    if (!this.isLocatie(locatie)) {
      throw new Error("Ongeldige locatie: " + locatie);
    }
    return locatie;
  }

  static isLocatie(locatie: string): locatie is BoekLocatie {
    return this.LOCATIES.includes(locatie);
  }

  static getDatabaseLocaties(locatie: BoekLocatie): DatabaseBoekLocatie[] {
    switch (locatie) {
      case "GHB":
        return ["GHB", "GHB CME", "GHB PO"];
      case "DIEST":
        return ["DIEST"];
    }
  }

  static getLabo(locatie: DatabaseBoekLocatie): BoekLabo {
    switch (locatie) {
      case "GHB":
      case "DIEST":
        return "klinische biologie";
      case "GHB CME":
        return "genetica";
      case "GHB PO":
        return "pathologische ontleedkunde";
    }
  }

  static getCode(arg: ParamMap | string): number | null {
    const code: string | null = (typeof arg === "string") ? arg : arg.get("code");
    if (!code) {
      return null;
    }
    if (!this.isCode(code)) {
      throw new Error("Ongeldige code: " + code);
    }
    return +code;
  }

  static isCode(code: string): boolean {
    const n: number = +code;
    return n > 0 && Number.isInteger(n);
  }
}
